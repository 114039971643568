import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SEO from "../components/SEO";
import { ADMIN_BASE_URL } from "../utilities/constants";

const NotFound = () => {
  const seoProps = {
    title: "Not Found | Creative Artworks and Exhibitions",
    description: "This page could not be found.",
    image: "https://images.bewakoof.com/lib/icon/app-logo.png",
    twitterCard: "summary_large_image",
    url: ADMIN_BASE_URL,
    keywords: "photography, exhibition, portfolio",
  };

  return (
    <>
      <SEO props={seoProps} />

      <Grid
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ margin: 0, padding: 0, height: "100%" }}
        container
      >
        <Grid item>
          <div>
            <h2>
              This page could not be found.
              <span> Go </span>
              <Link to="/">home</Link>
              <span>.</span>
            </h2>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default NotFound;
