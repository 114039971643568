import { ThemeOption } from "../interfaces/themes";
import themeSizes from "./size";

export default function themeTypography(theme: ThemeOption) {
  const size = themeSizes();
  return {
    fontFamily: "'ABC Favorit', 'Roboto', sans-serif",
    h6: {
      fontWeight: 500,
      color: theme.heading,
      fontSize: size[12],
    },
    h5: {
      fontSize: size[14],
      color: theme.heading,
      fontWeight: 500,
    },
    h4: {
      fontSize: size[16],
      color: theme.heading,
      fontWeight: 600,
    },
    h3: {
      fontSize: size[20],
      color: theme.heading,
      fontWeight: 600,
    },
    h2: {
      fontSize: size[24],
      color: theme.heading,
      fontWeight: 700,
    },
    h1: {
      fontSize: size[30],
      color: theme.heading,
      fontWeight: 800,
      lineHeight: "1.33",
    },
    mainContent: {
      backgroundColor: "#fff",
      width: "100%",
      minHeight: "100vh",
      flexGrow: 1,
    },
    mainMenu: {
      textTransform: "uppercase" as const,
      fontWeight: 500,
      fontSize: size[14],
      cursor: "pointer",
      p: "5px 8px",
      m: "0 3px",
      borderRadius: "6px",
      "&:hover": {
        backgroundColor: "#eaeaea",
      },
    },
    lightMenu: {
      textTransform: "uppercase" as const,
      fontWeight: 500,
      fontSize: size[11],
      letterSpacing: "0.5px",
      color: theme.colors.grey900,
    },
    subtitle: {
      fontSize: size[15],
      fontWeight: 600,
      color: theme.colors.grey700,
    },
    subtitle1: {
      fontSize: size[16],
      fontWeight: 500,
      color: "#a0a0a0",
      lineHeight: "1.44",
    },
    subtitle2: {
      fontSize: size[14],
      fontWeight: 400,
      color: theme.darkTextSecondary,
    },
    subtitle3: {
      fontSize: size[12],
      color: theme.colors.grey500,
      fontWeight: 400,
    },
    caption: {
      fontSize: size[12],
      color: theme.darkTextSecondary,
      fontWeight: 400,
    },
    footer: {
      fontSize: size[12],
      lineHeight: "18px",
      color: "#ffffffe6",
      fontWeight: 600,
    },
    breadcrumb: {
      padding: "15px 0",
      "& .MuiBreadcrumbs-ol": {
        "& .MuiBreadcrumbs-separator": {
          margin: "0 14px",
          fontSize: size[12],
          color: "#2d2d2d",
          fontWeight: 500,
        },
      },
    },
    breadcrumbText: {
      fontWeight: 500,
      color: "#535353",
      fontSize: size[12],
    },
    buttonList: {
      fontSize: size[15],
      fontWeight: 500,
      lineHeight: "1.334rem",
      color: theme.colors.grey900,
    },
    body1: {
      fontSize: size[16],
      fontWeight: 400,
      lineHeight: "1.334rem",
      color: theme.darkTextPrimary,
    },
    body2: {
      fontSize: size[16],
      fontWeight: 400,
      lineHeight: "1.334rem",
      color: theme.darkTextPrimary,
    },
    menu: {
      fontSize: size[15],
      fontWeight: 500,
      color: "#000000cc",
      ":hover": {
        color: "#000000e6",
      },
    },
    button: {
      // https://github.com/mui/material-ui/issues/16307#issuecomment-902344903
      textTransform: "capitalize" as const,
    },
    smallFormControl: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        color: theme.darkTextSecondary,
        fontWeight: 400,
        '&[data-shrink="false"]': {
          color: theme.colors?.grey500,
          marginTop: "-4px",
          fontSize: size[14],
        },
      },
      "& legend": {
        // width: "fit-content",
        // paddingLeft: "2px",
      },
    },
    customInput: {
      marginTop: 1,
      marginBottom: 1,
      "& > label": {
        top: 23,
        left: 0,
        fontSize: size[18],
        '&[data-shrink="false"]': {
          top: 5,
          fontSize: size[16],
        },
      },
      "& > div > input": {
        padding: "30.5px 14px 11.5px !important",
      },
      "& legend": {
        display: "none",
      },
      "& fieldset": {
        top: 0,
      },
    },

    menuCaption: {
      fontSize: size[14],
      fontWeight: 500,
      color: theme.heading,
      padding: "6px",
      textTransform: "capitalize",
      marginTop: "10px",
    },
    subMenuCaption: {
      fontSize: size[11],
      fontWeight: 500,
      color: theme.darkTextSecondary,
      textTransform: "capitalize",
    },
  };
}
