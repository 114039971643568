import {
  Button,
  CardMedia,
  Card,
  FormHelperText,
  Box,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import { Check, DeleteOutline, RefreshOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import randomService from "../../api/services/random.service";
import { imgUrl } from "../../utilities/helpers";
import FormRandom from "./FormRandom";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import Loader from "../../components/Loader";
import imageBlockService from "../../api/services/imageBlock.service";
import ColorPicker from "../Projects/ImageBlocks/ColorPicker";
import FormSeoRandoms from "./FormSeoRandoms";

const DesktopRandom = () => {
  const theme: Theme = useTheme();

  const [randoms, setRandoms] = useState([]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [submittingRanks, setSubmittingRanks] = useState(false);

  const [colors, setColors] = useState<any>({});
  const [updatedColors, setUpdatedColors] = useState<any>({});
  const [selectedRandom, setSelectedRandom] = useState<boolean>(null);
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
  const [openSeo, setOpenSeo] = useState<any>(false);

  function initializeRandoms() {
    randomService
      .getRandomAll()
      .then((res) => {
        if (res.data.success) {
          const items = res.data.resource;
          const filtered = items.filter((image) => image?._id);
          setRandoms(filtered);
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setError(error.message);
      });
  }

  // If Editing
  useEffect(() => {
    initializeRandoms();
  }, []);

  const refreshRandoms = () => {
    setError("");
    setSuccess("");
    initializeRandoms();
  };

  const handleClose = () => {
    setOpen((old) => !old);
  };

  const handleDeleteRandom = (random: any) => {
    setError("");
    var answer = window.confirm("Are you sure?");
    if (!answer) return;

    imageBlockService
      .deleteImage(random._id)
      .then((res) => {
        if (res.data.success) {
          refreshRandoms();
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  };

  const handleOnDragEndUploaded = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(randoms);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setRandoms(items);

    const ids = items.map((item: any) => item._id);
    handleUpdateRank(ids);
  };

  const handleUpdateRank = (items: any) => {
    setSubmittingRanks(true);
    randomService
      .updateRandomsRank(items)
      .then((res) => {
        if (res.data.success) {
          // Success
        } else {
          console.log(res.data.error?.message);
        }
        setSubmittingRanks(false);
      })
      .catch((error) => {
        setSubmittingRanks(false);
        console.log("Error Randoms Ranks:", error);
      });
  };

  function handleUpdateRandomColor(id: string) {
    setError("");
    const color = colors[id];

    if (!color) return;

    imageBlockService
      .updateImage({ color }, id)
      .then((res) => {
        if (res.data.success) {
          const _colors = Object.assign({}, colors);

          const _updatedColors = Object.assign({}, updatedColors);
          _updatedColors[id] = color;
          setUpdatedColors(_updatedColors);
          delete _colors[id];
          setColors(_colors);
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  const handleCloseColorPicker = () => {
    setOpenColorPicker(false);
    setSelectedRandom(null);
  };

  const handleOpenColorPicker = (image: any) => {
    setSelectedRandom(image);
    setOpenColorPicker(true);
  };

  const handleOpenFormSeo = () => {
    setOpenSeo((old) => !old);
  };

  return (
    <>
      {submittingRanks && <Loader />}
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          width: "100%",
          mt: "65px",
          height: "calc(100vh - 65px)",
        }}
      >
        <Button
          onClick={handleOpenFormSeo}
          sx={{ width: { xs: "100%", sm: "200px" }, mb: "20px" }}
        >
          Edit Randomize Page Seo
        </Button>

        {error && (
          <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
            {error}
          </FormHelperText>
        )}

        {success && (
          <FormHelperText sx={{ pb: "15px" }}>{success}</FormHelperText>
        )}

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          sx={{
            width: "100%",
            pb: "20px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{ width: { xs: "100%", sm: "200px" } }}
          >
            Add Image
          </Button>

          <Button onClick={refreshRandoms} sx={{ minWidth: "32px" }}>
            <RefreshOutlined />
            &nbsp;&nbsp;Refresh
          </Button>
        </Stack>

        {open && (
          <FormRandom setError={setError} refreshRandoms={refreshRandoms} />
        )}

        <Box sx={{ mt: "15px", width: "100%" }}>
          <DragDropContext onDragEnd={handleOnDragEndUploaded}>
            <Droppable droppableId="uploaded-images" direction="horizontal">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ mt: "10px", width: "100%" }}
                >
                  {randoms.map((random, index) => (
                    <Draggable
                      key={random._id}
                      draggableId={random._id}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Card
                            id={random._id}
                            elevation={0}
                            sx={{
                              padding: "0",
                              margin: "4px",
                              borderRadius: "2px",
                              position: "relative",
                              width: "fit-content",
                              maxHeight: "120px",
                            }}
                          >
                            {random.slug && (
                              <CardMedia
                                sx={{
                                  height: "120px",
                                  width: "fit-content",
                                  display: "inline-block",
                                }}
                                component="img"
                                src={imgUrl(random.slug)}
                                alt={random.alt}
                              />
                            )}

                            <IconButton
                              onClick={() => handleDeleteRandom(random)}
                              sx={{
                                color: theme.colors.error,
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Card>

                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0.8}
                            sx={{ m: "4px" }}
                          >
                            <Box
                              onClick={() => handleOpenColorPicker(random)}
                              sx={{
                                backgroundColor:
                                  colors[random._id] ||
                                  updatedColors[random._id] ||
                                  random.color,
                                height: "28px",
                                width: "28px",
                                cursor: "pointer",
                                borderRadius: "4px",
                                border: "1px solid #e6e6e6",
                              }}
                            />
                            <Typography>{random.color}</Typography>
                            {colors[random._id] && (
                              <Button
                                onClick={() =>
                                  handleUpdateRandomColor(random._id)
                                }
                                sx={{ minWidth: "auto" }}
                              >
                                <Check sx={{ height: "16px", width: "16px" }} />
                              </Button>
                            )}
                          </Stack>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Stack>

      {selectedRandom && (
        <ColorPicker
          colors={colors}
          setColors={setColors}
          image={selectedRandom}
          open={openColorPicker}
          handleClose={handleCloseColorPicker}
        />
      )}

      <FormSeoRandoms open={openSeo} handleClose={handleOpenFormSeo} />
    </>
  );
};

export default DesktopRandom;
