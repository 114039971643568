import { Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { SketchPicker } from "react-color";

const ColorPicker = ({ colors, setColors, image, open, handleClose }) => {
  const [color, setColor] = useState<any>({
    hex: colors[image._id] || image.color,
  });

  const handleColorChange = () => {
    const { hex } = color;
    const _colors = Object.assign({}, colors);
    _colors[image._id] = hex;

    setColors(_colors);
  };

  const handleChange = (col) => {
    setColor(col);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={() => {
        handleColorChange();
        handleClose();
      }}
      scroll="body"
    >
      <DialogContent sx={{ p: 0 }}>
        <SketchPicker color={color} onChangeComplete={handleChange} />
      </DialogContent>
    </Dialog>
  );
};

export default ColorPicker;
