import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  FormHelperText,
  ListItem,
  Chip,
  Box,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Theme, useTheme } from "@mui/material/styles";
import biographyService from "../../api/services/biography.service";
import { convertEditorToRaw } from "../../utilities/helpers";

const FormPublications = ({
  biography,
  setBiography,
  open,
  handleClose,
  refreshBiography,
}) => {
  const theme: Theme = useTheme();

  const [error, setError] = useState<string>("");
  const [timeError, setTimeError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [publications, setPublications] = useState<any>([]);
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [oldPub, setOldPub] = useState<any>(null);
  const [update, setUpdate] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setSuccess("");
    setError("");
    if (!biography) return;
    setPublications(biography.publications);
  }, [biography]);

  function createPublications(values: any) {
    biographyService
      .create(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Publications created");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  function updatePublications(values: any) {
    biographyService
      .updateDefault(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Publications updated");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  const handleSubmitPublications = () => {
    setSuccess("");
    setError("");
    setTimeError("");
    setSubmitting(true);

    if (biography) {
      updatePublications({ publications });
    } else {
      const about = convertEditorToRaw(EditorState.createEmpty());
      const params = { exhibitions: [], about, clients: [], publications };
      createPublications(params);
    }
  };

  const handleDeletePublication = (_name: string, _url: string) => {
    const items = publications.filter((item) => {
      if (item.name === _name && item.url === _url) return false;
      return true;
    });
    setPublications(items);
  };

  function validUrl(_url) {
    let foo
    try {
      foo = new URL(_url);
    } catch {
      console.log("catch");
      return false;
    }
    if (foo) {
      return true;
    }
  }

  const handleEditPublication = () => {
    setSuccess("");
    setError("");
    setTimeError("");

    if (!oldPub) return;

    const oldName = oldPub.name;
    const oldUrl = oldPub.url;

    const _name = name.trim();
    const _url = url.trim();
    if (_url) {
      if (!validUrl(_url)) {
        setTimeError("Url entered is invalid");
        return;
      }
    }

    if (!_name) {
      setTimeError("Name is required");
      return;
    }

    const included = publications.find((item) => {
      if (item.name === _name && item.url === _url) return true;
      return false;
    });

    if (included) {
      setTimeError("Identical publication already exists");
      return;
    }

    const items = publications.filter((item) => {
      if (item.name === oldName && item.url === oldUrl) return false;
      return true;
    });

    items.push({ name: _name, url: _url });
    setPublications(items);

    setName("");
    setTimeError("");
    setUpdate(false);
    setOldPub(null);
  };

  const handleAddPublication = () => {
    setSuccess("");
    setError("");
    setTimeError("");
    const _name = name.trim();
    const _url = url.trim();
    if (_url) {
      if (!validUrl(_url)) {
        setTimeError("Url entered is invalid");
        return;
      }
    }

    if (!_name) {
      setTimeError("Name is required");
      return;
    }
    const included = publications.find((item) => {
      if (item.name === _name && item.url === _url) return true;
      return false;
    });

    if (included) {
      setTimeError("Identical publication already exists");
      return;
    }

    setPublications((old) => [...old, { name: _name, url: _url }]);
    setName("");
    setTimeError("");
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} scroll="body">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", p: { xs: "10px 15px 0", sm: "20px 24px 0" } }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            disableElevation
            disabled={submitting}
            fullWidth
            onClick={handleSubmitPublications}
            sx={{ width: "30%" }}
          >
            Update Publications
          </Button>

          <Typography
            sx={{
              fontWeight: "700",
              fontSize: theme.size[15],
              color: theme.colors.grey700,
            }}
          >
            Publications Form
          </Typography>
        </Stack>

        <IconButton
          style={{ color: "gray" }}
          onClick={() => {
            refreshBiography();
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Stack>

      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          {timeError && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
              {timeError}
            </FormHelperText>
          )}

          {error && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
              {error}
            </FormHelperText>
          )}

          {success && (
            <FormHelperText sx={{ color: theme.colors.success, pb: "15px" }}>
              {success}
            </FormHelperText>
          )}

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              label="Name *"
              value={name}
              name="name"
              size="small"
              onBlur={(e) => setName(e.target.value)}
              onChange={(e) => setName(e.target.value)}
              sx={{ width: "100%" }}
            />

            <TextField
              fullWidth
              label="Link"
              value={url}
              name="url"
              size="small"
              onBlur={(e) => setUrl(e.target.value)}
              onChange={(e) => setUrl(e.target.value)}
              sx={{ width: "100%" }}
            />

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={1}
              sx={{ width: "100%" }}
            >
              {update && (
                <Button onClick={handleEditPublication} sx={{ width: "30%" }}>
                  Update Publication
                </Button>
              )}
              {!update && (
                <Button onClick={handleAddPublication} sx={{ width: "30%" }}>
                  Add Publication
                </Button>
              )}
            </Stack>
          </Stack>

          <Box sx={{ width: "100%" }}>
            {publications.map((pub, index) => (
              <ListItem
                key={`${pub.name}-${index}`}
                sx={{
                  width: "100%",
                  pl: "0",
                  pr: "0",
                }}
              >
                <Chip
                  icon={null}
                  label={
                    <Box
                      onClick={() => {
                        setName(pub.name);
                        setUrl(pub.url);
                        setUpdate(true);
                        setOldPub(pub);
                      }}
                      sx={{ width: "100%", cursor: "pointer" }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>
                        {pub.name}
                      </Typography>
                      <Typography>{pub.url}</Typography>
                    </Box>
                  }
                  onDelete={() => handleDeletePublication(pub.name, pub.url)}
                  sx={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    p: "5px 9px",
                    "& .MuiChip-label": {
                      width: "95%",
                      overflow: "auto",
                      textOverflow: "initial",
                      p: "0",
                      mr: "10px",
                      whiteSpace: "normal",
                    },
                  }}
                />
              </ListItem>
            ))}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FormPublications;
