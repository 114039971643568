import {
  DeleteDataType,
  ProjectType,
  ProjectDataType,
  SlugsDataType,
  ProjectsDataType,
} from "../../interfaces/services";
import http from "../http";

class ProjectService {
  getSlugs() {
    return http.get<SlugsDataType>("/projects/slugs");
  }

  getAll() {
    return http.get<ProjectsDataType>("/projects");
  }

  getAllShallow() {
    return http.get<ProjectsDataType>("/projects-shallow");
  }

  get(slug: string) {
    return http.get<ProjectDataType>(`/projects/${slug}`);
  }

  create(data: ProjectType) {
    return http.post<ProjectDataType>("/projects", data);
  }

  uploadProjectPreviewImage(data: any, project: string) {
    return http.post<any>(`/project-preview-image/${project}`, data);
  }

  getProjectPreviewImage(project: string) {
    return http.get<any>(`/project-preview-image/${project}`);
  }

  update(data: ProjectType, slug: string) {
    return http.put<ProjectDataType>(`/projects/${slug}`, data);
  }

  updateRank(data: string[]) {
    return http.put<ProjectDataType>(`/projects-rank`, data);
  }

  updateCategoryRank(data: string[]) {
    return http.put<ProjectDataType>(`/projects-category-rank`, data);
  }

  delete(slug: string) {
    return http.delete<DeleteDataType>(`/projects/${slug}`);
  }

  duplicate(slug: string) {
    return http.get<any>(`/projects/duplicate/${slug}`);
  }
}

export default new ProjectService();
