import { SeoType } from "../interfaces/components";

const SEO: React.FC<SeoType> = ({ props }) => {
  const { description, title, image, url, keywords, twitterCard } = props;
  let tempImage = image;

  if (!image) {
    tempImage = "/images/two.jpg";
  }

  return (
    <head>
      {/* HTML Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Yall" />

      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={tempImage} />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content="Yall" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={tempImage} />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/Yall"
      />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={tempImage} />
      <meta name="twitter:creator" content="@Yall" />
    </head>
  );
};

export default SEO;
