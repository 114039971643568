import { Card, CardMedia, CardHeader, Box } from "@mui/material";
import React from "react";
import { blockInfo, blockRatio } from "../../../utilities/helpers";
import { ClickAwayListener } from "@mui/base";

const BlockInfo = ({ identifier, setShowBlockInfo }) => {
  return (
    <ClickAwayListener onClickAway={() => setShowBlockInfo("")}>
      <Box
        onMouseLeave={() => setShowBlockInfo("")}
        sx={{
          position: "absolute",
          zIndex: 1100,
          m: "-50px -30px -30px -10px",
          p: "50px",
          backgroundColor: "transparent",
        }}
      >
        <Card
          elevation={0}
          sx={{
            padding: "15px",
            borderRadius: "5px",
            width: "fit-content",
            boxShadow: "0px 0px 30px 19px rgb(0 0 0 / 12%)",
          }}
        >
          <CardHeader
            title={`Image Block ${identifier}`}
            subheader={
              <>
                <span>{blockInfo(identifier)}</span>
                <br />
                <span>{blockRatio(identifier)}</span>
              </>
            }
            sx={{ p: "0 0 15px 0" }}
          />
          <CardMedia
            sx={{
              height: "120px",
              width: "fit-content",
              display: "inline-block",
            }}
            component="img"
            src={`/blocks/${identifier}.png`}
            alt={`Image Block ${identifier}`}
          />
        </Card>
      </Box>
    </ClickAwayListener>
  );
};

export default BlockInfo;
