/* eslint-disable react/jsx-props-no-spreading */
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Theme, useTheme } from "@mui/material/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import RichEditor from "../../components/RichEditor";
import projectService from "../../api/services/project.service";
import {
  convertEditorToRaw,
  getRawContent,
  stringToSlug,
} from "../../utilities/helpers";

const FormProject = ({
  rank,
  categoryRank,
  categories,
  project,
  setProject,
  open,
  handleClose,
  refreshProjects,
}) => {
  const theme: Theme = useTheme();

  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [about, setAbout] = useState(EditorState.createEmpty());
  const [submitting, setSubmitting] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    category: Yup.string(),
    slug: Yup.string(),
    client: Yup.string(),
    seoTitle: Yup.string(),
    seoDescription: Yup.string(),
  });

  // Initial values for the form
  const initialValues = {
    title: `${project?.title || ""}`,
    category: `${project?.category || "Select"}`,
    client: `${project?.client || ""}`,
    seoTitle: `${project?.seoTitle || ""}`,
    seoDescription: `${project?.seoDescription || ""}`,
  };

  const getEditorState = (string: string, setResource: any) => {
    const raw = getRawContent(string);
    const editorState = EditorState.createWithContent(raw);
    setResource(editorState);
  };

  // If Editing
  useEffect(() => {
    if (!project) return;
    getEditorState(project.about, setAbout);
  }, [project]);

  function createProject(values: any) {
    values.slug = stringToSlug(values.title);
    values.rank = rank;
    values.categoryRank = categoryRank;
    projectService
      .create(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Project created");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  }

  function updateProject(values: any) {
    values.slug = stringToSlug(values.title);

    projectService
      .update(values, project.slug)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Project updated");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  }

  const handleSubmitProject = (_values: any) => {
    const values = Object.assign({}, _values);

    // Clean and assign
    values.about = convertEditorToRaw(about);
    if (values.category === "Select") delete values.category;

    if (project) {
      updateProject(values);
    } else {
      createProject(values);
    }

    // Create
    setSubmitting(false);
  };

  const handleResetForm = () => {
    setError("");
    setSuccess("");
    setAbout(EditorState.createEmpty());
    setProject(null);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} scroll="body">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", p: { xs: "10px 15px 0", sm: "20px 24px 0" } }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: theme.size[17],
            color: theme.colors.grey700,
          }}
        >
          {`${project ? "Update" : "Create"} project`}
        </Typography>

        <IconButton
          style={{ color: "gray" }}
          onClick={() => {
            handleResetForm();
            handleClose();
            refreshProjects();
          }}
        >
          <Close />
        </IconButton>
      </Stack>

      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values /* { resetForm } */) => {
            // reset submit errors
            setError("");
            setSuccess("");
            setSubmitting(true);

            await handleSubmitProject(values);
          }}
        >
          {({ values, errors, handleChange, handleBlur, resetForm }) => (
            <Form noValidate>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                sx={{ width: "100%", mb: 3 }}
              >
                <Button
                  fullWidth
                  disableElevation
                  onClick={() => {
                    resetForm();
                    handleResetForm();
                  }}
                  sx={{ width: "30%" }}
                >
                  Reset Form
                </Button>

                <Button
                  disableElevation
                  disabled={submitting}
                  fullWidth
                  type="submit"
                  sx={{ width: "70%" }}
                >
                  {`${project ? "Update" : "Create"}`}
                </Button>
              </Stack>

              {error && (
                <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
                  {error}
                </FormHelperText>
              )}

              {success && (
                <FormHelperText
                  sx={{ color: theme.colors.success, pb: "15px" }}
                >
                  {success}
                </FormHelperText>
              )}

              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ width: "100%" }}
                >
                  <TextField
                    fullWidth
                    label="Title"
                    value={values.title}
                    name="title"
                    size="small"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!errors.title}
                    helperText={errors.title}
                    sx={{ width: "100%" }}
                  />

                  <TextField
                    fullWidth
                    label="Seo Title"
                    value={values.seoTitle}
                    name="seoTitle"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={values.title || "Title as default Seo Title"}
                    error={!!errors.seoTitle}
                    helperText={errors.seoTitle}
                    sx={{ width: "100%" }}
                  />

                  <TextField
                    fullWidth
                    label="Seo Description"
                    value={values.seoDescription}
                    name="seoDescription"
                    size="small"
                    multiline
                    rows={2}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!errors.seoDescription}
                    helperText={errors.seoDescription}
                    sx={{ width: "100%" }}
                  />
                </Stack>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  sx={{ width: "100%" }}
                >
                  <TextField
                    fullWidth
                    label="Client"
                    value={values.client}
                    name="client"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!errors.client}
                    helperText={errors.client}
                    sx={{ width: { xs: "100%", sm: "65%" } }}
                  />

                  <TextField
                    fullWidth
                    select
                    label="Category"
                    name="category"
                    size="small"
                    value={values.category}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!errors.category}
                    helperText={errors.category}
                    sx={{
                      width: { xs: "100%", sm: "35%" },
                      "& .MuiOutlinedInput-input": { p: "7px 14px" },
                    }}
                  >
                    <MenuItem value="Select">Select</MenuItem>
                    {categories.map((cat) => (
                      <MenuItem key={cat._id} value={cat._id}>
                        {cat.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <RichEditor
                  editorState={about}
                  setEditorState={setAbout}
                  placeholder="About project..."
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FormProject;
