// material-ui
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

// styles
const LoaderWrapper = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1300,
  width: "100%",
});

// ==============================|| LOADER ||============================== //
const LoaderBigger = () => {
  return (
    <LoaderWrapper>
      <LinearProgress sx={{ color: "#000", height: "8px" }} />
    </LoaderWrapper>
  );
};

export default LoaderBigger;
