import {
  Button,
  Typography,
  FormHelperText,
  Box,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  DeleteOutline,
  DriveFileRenameOutline,
  InfoOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import randomService from "../../api/services/random.service";
import FormRandomBlock from "./FormRandomBlock";
import { randomBlocks } from "../../utilities/constants";
import { flattenImageArray } from "../../utilities/helpers";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import Loader from "../../components/Loader";
import RandomBlockInfo from "./RandomBlockInfo";
import FormSeoRandoms from "../DesktopRandom/FormSeoRandoms";

const MobileRandom = () => {
  const theme: Theme = useTheme();

  const [blocks, setBlocks] = useState([]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const [submittingRanks, setSubmittingRanks] = useState(false);
  const [showBlockInfo, setShowBlockInfo] = useState<string>("");
  const [openSeo, setOpenSeo] = useState<any>(false);

  function initializeImageBlocks() {
    randomService
      .getRandomBlockAll()
      .then((res) => {
        if (res.data.success) {
          setBlocks(res.data.resource);
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setError(error.message);
      });
  }

  // If Editing
  useEffect(() => {
    initializeImageBlocks();
  }, []);

  const refreshImageBlocks = () => {
    setError("");
    setSuccess("");
    initializeImageBlocks();
  };

  const handleClose = () => {
    setOpen((old) => !old);
    setSelectedBlock(null);
  };

  const handleEditBlock = (block: any, index: number) => {
    setSelectedBlock(block);
    setOpen(true);
  };

  const handleDeleteBlock = (block: any) => {
    setError("");
    var answer = window.confirm("Are you sure?");
    if (!answer) return;

    randomService
      .deleteRandomBlock(block._id)
      .then((res) => {
        if (res.data.success) {
          refreshImageBlocks();
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setBlocks(items);

    const ids = items.map((item: any) => item._id);
    handleUpdateRank(ids);
  };

  const handleUpdateRank = (items: any) => {
    setSubmittingRanks(true);
    randomService
      .updateRandomBlocksRank(items)
      .then((res) => {
        if (res.data.success) {
          // Success
        } else {
          console.log(res.data.error?.message);
        }
        setSubmittingRanks(false);
      })
      .catch((error) => {
        setSubmittingRanks(false);
        console.log("Error Block Ranks:", error);
      });
  };

  const handleOpenFormSeo = () => {
    setOpenSeo((old) => !old);
  };

  return (
    <>
      {submittingRanks && <Loader />}

      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          width: "100%",
          mt: "65px",
          height: "calc(100vh - 65px)",
        }}
      >
        <Button
          onClick={handleOpenFormSeo}
          sx={{ width: { xs: "100%", sm: "200px" }, mb: "20px" }}
        >
          Edit Randomize Page Seo
        </Button>

        {error && (
          <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
            {error}
          </FormHelperText>
        )}

        {success && (
          <FormHelperText sx={{ pb: "15px" }}>{success}</FormHelperText>
        )}

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          sx={{
            width: "100%",
            pb: "20px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{ width: { xs: "100%", sm: "200px" } }}
          >
            New Random Block
          </Button>

          <Button onClick={refreshImageBlocks} sx={{ minWidth: "32px" }}>
            <RefreshOutlined />
            &nbsp;&nbsp;Refresh
          </Button>
        </Stack>

        {open && (
          <FormRandomBlock
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
            refreshBlocks={refreshImageBlocks}
            setError={setError}
            setSuccess={setSuccess}
            rank={blocks.length}
          />
        )}

        {blocks.length > 0 && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {flattenImageArray(blocks).map((image) => (
              <Box
                key={image._id}
                sx={{
                  height: "20px",
                  width: "6px",
                  backgroundColor: image.color,
                  p: "3px 0",
                }}
              />
            ))}
          </Stack>
        )}

        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ width: "100%", mt: "15px" }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{
              width: "100%",
              backgroundColor: theme.colors.grey200,
              padding: "8px",
              borderRadius: "4px",
              mb: "10px",
            }}
          >
            <Typography
              component="div"
              sx={{
                width: "100px",
                fontSize: { xs: theme.size[10], sm: theme.size[12] },
                fontWeight: 400,
                lineHeight: {
                  xs: theme.size[13],
                  sm: theme.size[22],
                },
              }}
            >
              Block Type
            </Typography>

            <Typography
              component="div"
              sx={{
                width: "100px",
                fontSize: { xs: theme.size[10], sm: theme.size[12] },
                fontWeight: 400,
                lineHeight: {
                  xs: theme.size[13],
                  sm: theme.size[22],
                },
              }}
            >
              Max Images
            </Typography>

            <Typography
              component="div"
              sx={{
                width: "50px",
                fontSize: { xs: theme.size[10], sm: theme.size[12] },
                fontWeight: 400,
                lineHeight: {
                  xs: theme.size[13],
                  sm: theme.size[22],
                },
              }}
            >
              Images
            </Typography>

            <Box />
            <Box />
          </Stack>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="blocks">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {blocks.map((block, index) => (
                    <Draggable
                      key={block._id}
                      draggableId={block._id}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            m: "10px 0",
                            p: "5px 0",
                            backgroundColor: "white",
                            borderRadius: "6px",
                            border: "1.5px solid #efefef",
                            "&:hover": {
                              backgroundColor: "#efefef",
                            },
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: "100%", padding: "0 8px" }}
                          >
                            <Typography
                              component="div"
                              sx={{
                                width: "100px",
                                fontSize: {
                                  xs: theme.size[10],
                                  sm: theme.size[12],
                                },
                                fontWeight: 400,
                                lineHeight: {
                                  xs: theme.size[13],
                                  sm: theme.size[22],
                                },
                              }}
                            >
                              {block.identifier}
                            </Typography>

                            <Typography
                              component="div"
                              sx={{
                                width: "100px",
                                fontSize: {
                                  xs: theme.size[10],
                                  sm: theme.size[12],
                                },
                                fontWeight: 400,
                                lineHeight: {
                                  xs: theme.size[13],
                                  sm: theme.size[22],
                                },
                              }}
                            >
                              {randomBlocks.find(
                                (b) => b.value === block.identifier
                              )?.count || "Infinite"}
                            </Typography>

                            <Typography
                              component="div"
                              sx={{
                                width: "50px",
                                fontSize: {
                                  xs: theme.size[10],
                                  sm: theme.size[12],
                                },
                                fontWeight: 400,
                                lineHeight: {
                                  xs: theme.size[13],
                                  sm: theme.size[22],
                                },
                              }}
                            >
                              {block.images.length}
                            </Typography>

                            <Tooltip title="Edit Block" placement="top">
                              <IconButton
                                onClick={() => handleEditBlock(block, index)}
                              >
                                <DriveFileRenameOutline />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Block" placement="top">
                              <IconButton
                                onClick={() => handleDeleteBlock(block)}
                                sx={{ color: theme.colors.error }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>

                            <Box sx={{ position: "relative" }}>
                              <IconButton
                                onClick={() => setShowBlockInfo(block._id)}
                                onMouseEnter={() => setShowBlockInfo(block._id)}
                              >
                                <InfoOutlined />
                              </IconButton>
                              {showBlockInfo === block._id && (
                                <RandomBlockInfo
                                  identifier={block.identifier}
                                  setShowBlockInfo={setShowBlockInfo}
                                />
                              )}
                            </Box>
                          </Stack>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
      </Stack>

      <FormSeoRandoms open={openSeo} handleClose={handleOpenFormSeo} />
    </>
  );
};

export default MobileRandom;
