import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../../components/Loader";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <>
        <Loader />
        <div>Redirecting you to the login page...</div>
      </>
    ),
  });

  return <Component {...args} />;
};

export default ProtectedRoute;
