import {
  CategoriesDataType,
  CategoryDataType,
  CategoryType,
  DeleteDataType,
  SlugsDataType,
} from "../../interfaces/services";
import http from "../http";

class CategoriesService {
  getSlugs() {
    return http.get<SlugsDataType>("/categories/paths");
  }

  getAll() {
    return http.get<CategoriesDataType>("/categories");
  }

  get(slug: string) {
    return http.get<CategoryDataType>(`/categories/${slug}`);
  }

  create(data: CategoryType) {
    return http.post<CategoryDataType>("/categories", data);
  }

  update(data: CategoryType, slug: string) {
    return http.put<CategoryDataType>(`/categories/${slug}`, data);
  }

  delete(slug: string) {
    return http.delete<DeleteDataType>(`/categories/${slug}`);
  }
}

export default new CategoriesService();
