import { Editor } from "react-draft-wysiwyg";
import { Box } from "@mui/material";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const RichEditor = ({ editorState, setEditorState, placeholder }) => {
  return (
    <Box
      sx={{
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        p: "8px",
        width: "100%",
      }}
    >
      <Box sx={{ m: "9px" }}>
        <Editor
          editorState={editorState}
          onEditorStateChange={(editorState) => setEditorState(editorState)}
          placeholder={placeholder || "Start writing here..."}
        />
      </Box>
    </Box>
  );
};

export default RichEditor;
