import { createTheme, Palette } from "@mui/material/styles";
import "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import colors from "../styles/_themes-vars.module.scss";
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import customShadows from "./shadow";
import themeSizes from "./size";
import themeTypography from "./typography";

declare module "@mui/material/styles" {
  interface Theme {
    colors: { [key: string]: string };
    size: { [key: number]: string };
    borderRadius: string;
  }

  interface TypographyVariants {
    mainContent: React.CSSProperties;
    mainMenu: React.CSSProperties;
    lightMenu?: React.CSSProperties;
    dropdownMenuContainer: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mainContent?: React.CSSProperties;
    mainMenu?: React.CSSProperties;
    lightMenu?: React.CSSProperties;
    dropdownMenuContainer?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    mainContent: true;
    mainMenu: true;
    lightMenu: true;
    dropdownMenuContainer: true;
  }
}

const themes = () => {
  const themeOption = {
    colors,
    heading: "#333333",
    paper: colors.paper,
    backgroundDefault: colors.paper,
    background: colors.grey100,
    darkTextPrimary: colors.black,
    darkTextSecondary: colors.grey800,
    textDark: colors.black,
    menuSelected: colors.darkPrimary800,
    menuSelectedBack: colors.grey200,
    divider: colors.grey400,
    borderRadius: "10px",
  };

  const themeOptions = {
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        justifyContent: "center",
        width: "inner-content",
        minHeight: "48px",
        "@media (min-width: 600px)": {
          padding: "0 25px",
        },
      },
    },
    typography: themeTypography(themeOption),
    borderRadius: "10px",
    colors,
    shadow: customShadows(),
    size: themeSizes(),
  };

  const theme = createTheme(themeOptions);
  theme.components = componentStyleOverrides(themeOption);

  return theme;
};

export default themes;
