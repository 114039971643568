import { convertFromRaw, convertToRaw } from "draft-js";
import DOMPurify from "dompurify";
import { stateToHTML } from "draft-js-export-html";
import { API_BASE_URL, CLOUDFRONT, imageBlocks } from "./constants";

// Capitalize first letter
export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Slugs & Compose complete api url
export function apiUrl(sub: string) {
  return API_BASE_URL + sub;
}

export function imgUrl(slug: string) {
  return CLOUDFRONT + slug;
}

export function parseSlugParam(param: string | string[]) {
  return Array.isArray(param) ? param[0] : param;
}

export function stringToSlug(value: string) {
  if (!value) return "";
  const parsed = value
    .replace(/[^a-z0-9_]+/gi, "-")
    .replace(/^-|-$/g, "")
    .toLowerCase();
  return parsed;
}

// Rich Text Editor

function createMarkup(html: any) {
  return {
    __html: DOMPurify.sanitize(html),
  };
}

export function convertContentToHTML(content: string) {
  let html = null;
  if (content) {
    const object = JSON.parse(content);
    const raw = convertFromRaw(object);
    html = stateToHTML(raw);
  }
  return createMarkup(html);
}

export function getRawContent(content: any) {
  const object = JSON.parse(content);
  const raw = convertFromRaw(object);
  return raw;
}

export function convertEditorToRaw(editorState: any) {
  const rawAbout = convertToRaw(editorState.getCurrentContent());
  return JSON.stringify(rawAbout);
}

// Split array into sub arrays
export function splitIntoSubArrays(items: any, chuckSize: number) {
  const aa = [];
  for (let i = 0; i < items.length; i += chuckSize) {
    aa.push(items.slice(i, i + chuckSize));
  }

  return aa;
}

// Get percent section of array

// Example, given 70%, we want to get the 70% items in the array
export function percentArray(items: any, percent: number) {
  const count = Math.floor((percent / 100) * items.length);
  return items.slice(0, count + 1);
}

// Then, get remaining items - 70%
export function percentRemainingArray(items: any, percent: number) {
  const count = Math.floor((percent / 100) * items.length);
  return items.slice(count + 1);
}

// Group array into smaller subgroups

export const imageChunks = (arr: any, splitBy: number) => {
  const cache = [];
  while (arr.length > 0) {
    cache.push(arr.splice(0, splitBy));
  }
  return cache;
};

// Flatten Image arrays

export const flattenImageArray = (blocks: Array<any>) => {
  const images = [];
  for (var i = 0; i < blocks.length; i++) {
    const blockImages = blocks[i].images;
    images.push.apply(images, blockImages);
  }
  return images;
};

// Get random number between min, max

export function getRandom(min: number, max: number) {
  return Math.floor(min + Math.random() * (max - min + 1));
}

// Test functions
export function minRand() {
  const min = 3;
  const max = 6;
  return Math.floor(min + Math.random() * (max - min + 1));
}

export function maxRand() {
  const min = 7;
  const max = 13;
  return Math.floor(min + Math.random() * (max - min + 1));
}

export function minMaxRand() {
  return [minRand(), maxRand()];
}

export function filterItemsBySlug(slug: string, items: any[]) {
  return items.filter((item) => item.slug === slug);
}

// Images and Parsing

export function parseImages(id: string, images: any) {
  const formData = new FormData();
  images.forEach((image: any) => {
    formData.append("images[]", image);
  });

  formData.append("id", id);
  return formData;
}

export const getParsedExhibitions = (items: any) => {
  let upcoming = [];
  let past = [];

  const date = new Date();
  const time = date.getTime();

  for (let i = 0; i < items.length; i++) {
    const exhibit = items[i];
    if (exhibit.time < time) {
      past = [...past, exhibit];
    } else {
      upcoming = [...upcoming, exhibit];
    }
  }

  return { upcoming, past };
};

export const blockInfo = (id: number) => {
  const { count } = imageBlocks.find((block) => block.value === id);
  return `Max ${count ? count : "infinite"} image(s)`;
};

export const blockRatio = (id: number) => {
  const { ratio } = imageBlocks.find((block) => block.value === id);
  return `Image Ratio: ${ratio}`;
};

export const getTotalSize = (images: any) => {
  let total = 0;
  for (let i = 0; i < images.length; i++) {
    total += images[i].size;
  }

  return total;
};
