import { Box, Stack, Button } from "@mui/material";
import { useState } from "react";
import UploadImages from "../../components/UploadImages";
// import { Theme, useTheme } from "@mui/material/styles";
import LoaderBigger from "../../components/LoaderBigger";
import randomService from "../../api/services/random.service";

const FormRandom = ({ setError, refreshRandoms }) => {
  // const theme: Theme = useTheme();
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [totalSize, setTotalSize] = useState<number>(0);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleBlockChange = (id: any) => {
    setImages((old) => [...old]);
    setPreviews((old) => [...old]);
  };

  function handleAddRandoms() {
    setSubmitting(true);
    if (images.length === 0) {
      setSubmitting(false);
      return;
    }
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("files", image);
    });

    const _colors = previews.map((image) => image.color);

    formData.append("colors", JSON.stringify(_colors));

    randomService
      .createRandoms(formData)
      .then((res) => {
        if (res.data.success) {
          setImages([]);
          setPreviews([]);
          setSubmitting(false);
          refreshRandoms();
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.message);
      });
  }

  return (
    <>
      {submitting && <LoaderBigger />}
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Button
          onClick={handleAddRandoms}
          disabled={totalSize > 20_000_000}
          sx={{
            p: "8px",
            width: "20%",
            backgroundColor: `${totalSize > 20_000_000 ? "red" : "#43a310"}`,
          }}
        >
          Submit
        </Button>

        <Box sx={{ width: "100%" }}>
          <UploadImages
            totalSize={totalSize}
            setTotalSize={setTotalSize}
            inputBoxSx={{ width: { xs: "100%", sm: "60%" } }}
            imageHeight="120px"
            multiple
            max={null}
            identifier={null}
            required
            handleBlockChange={handleBlockChange}
            files={images}
            setFiles={setImages}
            previews={previews}
            setPreviews={setPreviews}
          />
        </Box>
      </Stack>
    </>
  );
};

export default FormRandom;
