import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  FormHelperText,
  Stack,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Theme, useTheme } from "@mui/material/styles";
import biographyService from "../../api/services/biography.service";
import { convertEditorToRaw, getRawContent } from "../../utilities/helpers";
import RichEditor from "../../components/RichEditor";

const FormAbout = ({
  biography,
  setBiography,
  open,
  handleClose,
  refreshBiography,
}) => {
  const theme: Theme = useTheme();

  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [about, setAbout] = useState(EditorState.createEmpty());
  const [submitting, setSubmitting] = useState<boolean>(false);

  const getEditorState = (string: string, setResource: any) => {
    const raw = getRawContent(string);
    const editorState = EditorState.createWithContent(raw);
    setResource(editorState);
  };

  useEffect(() => {
    setSuccess("");
    setError("");
    if (!biography) return;
    getEditorState(biography.about, setAbout);
  }, [biography]);

  function createAbout(values: any) {
    biographyService
      .create(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("About created");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  function updateAbout(values: any) {
    biographyService
      .updateDefault(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("About updated");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  const handleSubmitAbout = () => {
    setSuccess("");
    setError("");
    setSubmitting(true);
    const converted = convertEditorToRaw(about);

    if (biography) {
      updateAbout({ about: converted });
    } else {
      const params = {
        exhibitions: [],
        about: converted,
        clients: [],
        publications: [],
      };
      createAbout(params);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} scroll="body">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", p: { xs: "10px 15px 0", sm: "20px 24px 0" } }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            disableElevation
            disabled={submitting}
            fullWidth
            onClick={handleSubmitAbout}
            sx={{ width: "30%" }}
          >
            Update About
          </Button>

          <Typography
            sx={{
              fontWeight: "700",
              fontSize: theme.size[15],
              color: theme.colors.grey700,
            }}
          >
            About Form
          </Typography>
        </Stack>

        <IconButton
          style={{ color: "gray" }}
          onClick={() => {
            refreshBiography();
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Stack>

      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          {error && (
            <FormHelperText sx={{ color: theme.colors.error }}>
              {error}
            </FormHelperText>
          )}

          {success && (
            <FormHelperText sx={{ color: theme.colors.success }}>
              {success}
            </FormHelperText>
          )}

          <RichEditor
            editorState={about}
            setEditorState={setAbout}
            placeholder="About Doris..."
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FormAbout;
