import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  FormHelperText,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Theme, useTheme } from "@mui/material/styles";
import biographyService from "../../api/services/biography.service";
import { convertEditorToRaw } from "../../utilities/helpers";

const FormSeoProjects = ({ open, handleClose }) => {
  const theme: Theme = useTheme();

  const [error, setError] = useState<string>("");
  const [timeError, setTimeError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [biography, setBiography] = useState<any>(null);
  const [seoProjectsTitle, setSeoProjectsTitle] = useState<string>("");
  const [seoProjectsDescription, setSeoProjectsDescription] =
    useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  function initializeBiography() {
    setError("");
    biographyService
      .getDefault()
      .then((res) => {
        if (res.data.success) {
          const bio = res.data.resource;
          if (!bio) return;
          setBiography(bio);
          setSeoProjectsTitle(`${bio.seoProjectsTitle || ""}`);
          setSeoProjectsDescription(`${bio.seoProjectsDescription || ""}`);
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setError(error.message);
      });
  }

  useEffect(() => {
    initializeBiography();
  }, []);

  function createSeo(values: any) {
    biographyService
      .create(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Seo created");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  function updateSeo(values: any) {
    biographyService
      .updateDefault(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Seo updated");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  const handleSubmitSeo = () => {
    setSuccess("");
    setError("");
    setTimeError("");
    setSubmitting(true);

    if (biography) {
      updateSeo({ seoProjectsTitle, seoProjectsDescription });
    } else {
      const about = convertEditorToRaw(EditorState.createEmpty());
      const params = {
        exhibitions: [],
        about,
        seoProjectsTitle,
        seoProjectsDescription,
        clients: [],
        publications: [],
      };
      createSeo(params);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} scroll="body">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", p: { xs: "10px 15px 0", sm: "20px 24px 0" } }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            disableElevation
            disabled={submitting}
            fullWidth
            onClick={handleSubmitSeo}
            sx={{ width: "30%" }}
          >
            Update Seo
          </Button>

          <Typography
            sx={{
              fontWeight: "700",
              fontSize: theme.size[15],
              color: theme.colors.grey700,
            }}
          >
            Seo Form
          </Typography>
        </Stack>

        <IconButton
          style={{ color: "gray" }}
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Stack>

      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          {timeError && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
              {timeError}
            </FormHelperText>
          )}

          {error && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
              {error}
            </FormHelperText>
          )}

          {success && (
            <FormHelperText sx={{ color: theme.colors.success, pb: "15px" }}>
              {success}
            </FormHelperText>
          )}

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              label="Seo Title"
              value={seoProjectsTitle}
              name="seoProjectsTitle"
              size="small"
              onBlur={(e) => setSeoProjectsTitle(e.target.value)}
              onChange={(e) => setSeoProjectsTitle(e.target.value)}
              placeholder="Contact"
              sx={{ width: "100%" }}
            />

            <TextField
              fullWidth
              label="Seo Description"
              value={seoProjectsDescription}
              name="seoProjectsDescription"
              size="small"
              multiline
              rows={2}
              onBlur={(e) => setSeoProjectsDescription(e.target.value)}
              onChange={(e) => setSeoProjectsDescription(e.target.value)}
              sx={{ width: "100%" }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FormSeoProjects;
