import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  FormHelperText,
  Box,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  DeleteOutline,
  DriveFileRenameOutline,
  Close,
  RefreshOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import NewBlock from "./NewBlock";
import imageBlockService from "../../../api/services/imageBlock.service";
import { imageBlocks } from "../../../utilities/constants";
import { flattenImageArray } from "../../../utilities/helpers";
import BlockInfo from "./BlockInfo";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import Loader from "../../../components/Loader";

const FormBlocks = ({ project, setProject, open, handleClose }) => {
  const theme: Theme = useTheme();

  const [blocks, setBlocks] = useState([]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showNewBlock, setShowNewBlock] = useState<boolean>(false);
  const [showBlockInfo, setShowBlockInfo] = useState<string>("");
  const [submittingRanks, setSubmittingRanks] = useState(false);

  function initializeImageBlocks(id: any) {
    imageBlockService
      .getProjectBlocks(id)
      .then((res) => {
        if (res.data.success) {
          setBlocks(res.data.resource);
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setError(error.message);
      });
  }

  // If Editing
  useEffect(() => {
    initializeImageBlocks(project._id);
  }, [project]);

  const refreshImageBlocks = () => {
    setError("");
    setSuccess("");
    initializeImageBlocks(project._id);
    if (selectedBlock) {
      setSelectedBlock(blocks[selectedIndex]);
    }
  };

  const handleShowNewBlock = () => {
    setShowNewBlock((old) => !old);
  };

  const handleEditBlock = (block: any, index: number) => {
    setSelectedBlock(block);
    setSelectedIndex(index);
    setShowNewBlock(true);
  };

  const handleDeleteBlock = (block: any) => {
    setError("");
    var answer = window.confirm("Are you sure?");
    if (!answer) return;

    imageBlockService
      .deleteBlock(block._id)
      .then((res) => {
        if (res.data.success) {
          refreshImageBlocks();
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setBlocks(items);

    const ids = items.map((item: any) => item._id);
    handleUpdateRank(ids);
  };

  const handleUpdateRank = (items: any) => {
    setSubmittingRanks(true);
    imageBlockService
      .updateBlockRank(items)
      .then((res) => {
        if (res.data.success) {
          // Success
        } else {
          console.log(res.data.error?.message);
        }
        setSubmittingRanks(false);
      })
      .catch((error) => {
        setSubmittingRanks(false);
        console.log("Error Block Ranks:", error);
      });
  };

  return (
    <>
      {submittingRanks && <Loader />}

      <Dialog fullScreen open={open} scroll="body">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", p: { xs: "10px 15px 0", sm: "20px 24px 0" } }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: theme.size[17],
              color: theme.colors.grey700,
            }}
          >
            {`Image Blocks | ${project.title}`}
          </Typography>

          <IconButton
            style={{ color: "gray" }}
            onClick={() => {
              handleClose();
              setProject(null);
            }}
          >
            <Close />
          </IconButton>
        </Stack>

        <DialogContent sx={{ height: "100%" }}>
          {error && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
              {error}
            </FormHelperText>
          )}

          {success && (
            <FormHelperText sx={{ pb: "15px" }}>{success}</FormHelperText>
          )}

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              width: "100%",
              pb: "20px",
            }}
          >
            <Button
              onClick={handleShowNewBlock}
              sx={{ width: { xs: "100%", sm: "200px" } }}
            >
              {`${selectedBlock ? "Update" : "New"} Image Block`}
            </Button>

            <Button onClick={refreshImageBlocks} sx={{ minWidth: "32px" }}>
              <RefreshOutlined />
              &nbsp;&nbsp;Refresh
            </Button>
          </Stack>

          {showNewBlock && (
            <Box
              sx={{
                width: "100%",
                p: "20px",
                border: `1px solid ${theme.colors.grey300}`,
                borderRadius: "5px",
                mb: "15px",
              }}
            >
              <NewBlock
                rank={blocks.length}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
                refreshBlocks={refreshImageBlocks}
                setError={setError}
                setSuccess={setSuccess}
                project={project}
              />
            </Box>
          )}

          {blocks.length > 0 && (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {flattenImageArray(blocks).map((image) => (
                <Box
                  key={image._id}
                  sx={{
                    height: "20px",
                    width: "6px",
                    backgroundColor: image.color,
                    p: "3px 0",
                  }}
                />
              ))}
            </Stack>
          )}

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: "100%", mt: "15px" }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
              sx={{
                width: "100%",
                backgroundColor: theme.colors.grey200,
                padding: "8px",
                borderRadius: "4px",
                mb: "10px",
              }}
            >
              <Typography
                component="div"
                sx={{
                  width: "100px",
                  fontSize: { xs: theme.size[10], sm: theme.size[12] },
                  fontWeight: 400,
                  lineHeight: {
                    xs: theme.size[13],
                    sm: theme.size[22],
                  },
                }}
              >
                Block Type
              </Typography>

              <Typography
                component="div"
                sx={{
                  width: "100px",
                  fontSize: { xs: theme.size[10], sm: theme.size[12] },
                  fontWeight: 400,
                  lineHeight: {
                    xs: theme.size[13],
                    sm: theme.size[22],
                  },
                }}
              >
                Max Images
              </Typography>

              <Typography
                component="div"
                sx={{
                  width: "50px",
                  fontSize: { xs: theme.size[10], sm: theme.size[12] },
                  fontWeight: 400,
                  lineHeight: {
                    xs: theme.size[13],
                    sm: theme.size[22],
                  },
                }}
              >
                Images
              </Typography>

              <Box />
              <Box />
            </Stack>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="blocks">
                {(provided) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {blocks.map((block, index) => (
                      <Draggable
                        key={block._id}
                        draggableId={block._id}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              m: "10px 0",
                              p: "5px 0",
                              backgroundColor: "white",
                              borderRadius: "6px",
                              border: "1.5px solid #efefef",
                              "&:hover": {
                                backgroundColor: "#efefef",
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={2}
                              sx={{ width: "100%", padding: "0 8px" }}
                            >
                              <Typography
                                component="div"
                                sx={{
                                  width: "100px",
                                  fontSize: {
                                    xs: theme.size[10],
                                    sm: theme.size[12],
                                  },
                                  fontWeight: 400,
                                  lineHeight: {
                                    xs: theme.size[13],
                                    sm: theme.size[22],
                                  },
                                }}
                              >
                                {block.identifier}
                              </Typography>

                              <Typography
                                component="div"
                                sx={{
                                  width: "100px",
                                  fontSize: {
                                    xs: theme.size[10],
                                    sm: theme.size[12],
                                  },
                                  fontWeight: 400,
                                  lineHeight: {
                                    xs: theme.size[13],
                                    sm: theme.size[22],
                                  },
                                }}
                              >
                                {imageBlocks.find(
                                  (b) => b.value === block.identifier
                                )?.count || "Infinite"}
                              </Typography>

                              <Typography
                                component="div"
                                sx={{
                                  width: "50px",
                                  fontSize: {
                                    xs: theme.size[10],
                                    sm: theme.size[12],
                                  },
                                  fontWeight: 400,
                                  lineHeight: {
                                    xs: theme.size[13],
                                    sm: theme.size[22],
                                  },
                                }}
                              >
                                {block.images.length}
                              </Typography>

                              <Tooltip title="Edit Block" placement="top">
                                <IconButton
                                  onClick={() => handleEditBlock(block, index)}
                                >
                                  <DriveFileRenameOutline />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Delete Block" placement="top">
                                <IconButton
                                  onClick={() => handleDeleteBlock(block)}
                                  sx={{ color: theme.colors.error }}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Tooltip>

                              <Box sx={{ position: "relative" }}>
                                <IconButton
                                  onClick={() => setShowBlockInfo(block._id)}
                                  onMouseEnter={() =>
                                    setShowBlockInfo(block._id)
                                  }
                                >
                                  <InfoOutlined />
                                </IconButton>
                                {showBlockInfo === block._id && (
                                  <BlockInfo
                                    identifier={block.identifier}
                                    setShowBlockInfo={setShowBlockInfo}
                                  />
                                )}
                              </Box>
                            </Stack>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormBlocks;
