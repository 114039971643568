import http from "../http";

class RandomService {
  getRandomAll() {
    return http.get<any>("/randoms");
  }

  createRandoms(data: any) {
    return http.post<any>("/randoms", data);
  }

  updateRandomsRank(data: string[]) {
    return http.put<any>(`/randoms-rank`, data);
  }

  getRandomBlockAll() {
    return http.get<any>("/random-blocks");
  }

  createRandomBlock(data: any) {
    return http.post<any>("/random-blocks", data);
  }

  createRandomBlocksImages(data: any, id: string) {
    return http.post<any>(`/random-block-images-upload/${id}`, data);
  }

  updateRandomBlocksRank(data: string[]) {
    return http.put<any>(`/random-blocks-rank`, data);
  }

  updateRandomBlock(data: any, id: string) {
    return http.put<any>(`/random-blocks/${id}`, data);
  }

  deleteRandomBlock(id: string) {
    return http.delete<any>(`/random-blocks/${id}`);
  }
}

export default new RandomService();
