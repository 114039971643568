import { ThemeOption } from "../interfaces/themes";
import themeSizes from "./size";

export default function componentStyleOverrides(theme: ThemeOption) {
  const size = themeSizes();
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          p: "6px 22px",
          fontWeight: 500,
          color: theme.colors.paper,
          backgroundColor: theme.colors.black,
          "&:hover": {
            backgroundColor: theme.colors.grey700,
          },
          "&.Mui-disabled": {
            color: "#ffffff",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: 0,
          fontSize: size[13],
          fontWeight: 400,
          color: theme.colors.black,
          fontFamily: "'ABC Favorit', 'Roboto', sans-serif",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: theme.borderRadius,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: size[18],
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          padding: "5px 10px",
          "&.Mui-selected": {
            color: theme.menuSelected,
            backgroundColor: theme.menuSelectedBack,
            "&:hover": {
              backgroundColor: theme.menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: theme.darkTextPrimary,
            },
          },
          "&:hover": {
            backgroundColor: theme.menuSelectedBack,
            color: theme.darkTextPrimary,
            "& .MuiListItemIcon-root": {
              color: theme.darkTextPrimary,
              // fontSize: size[16],
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          // Below is not working
          fontSize: size[14],
          "&::placeholder": {
            color: theme.darkTextSecondary,
            fontSize: size[14],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // background: "#fff",
          // borderRadius: theme.borderRadius,
          // "&.MuiInputBase-multiline": {
          //   padding: 1,
          // },
        },
        input: {
          // fontSize: size[14],
          // fontWeight: 600,
          // background: "#fff",
          // padding: "8.5px 14px",
          // borderRadius: theme.borderRadius,
          // "&.MuiInputBase-inputSizeSmall": {
          //   padding: "12px 14px",
          //   "&.MuiInputBase-inputAdornedStart": {
          //     paddingLeft: 0,
          //   },
          // },
        },
        inputAdornedStart: {
          // paddingLeft: 4,
        },
        notchedOutline: {
          // borderRadius: theme.borderRadius,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-root": {
            // color: theme.colors.black,
            // fontWeight: 500,
            fontSize: size[14],
            // '&[data-shrink="false"]': {
            //   color: theme.colors.grey800,
            // },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme?.colors?.primaryLight,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700,
        },
      },
    },
  };
}
