import { RestartAltOutlined } from "@mui/icons-material";
import {
  Box,
  Chip,
  ListItem,
  Stack,
  TextField,
  Button,
  FormHelperText,
} from "@mui/material";
import { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import categoryService from "../../api/services/category.service";
import { stringToSlug } from "../../utilities/helpers";

const Categories = ({
  categories,
  showForm,
  setShowForm,
  refreshCategories,
}) => {
  const theme: Theme = useTheme();
  const [selected, setSelected] = useState(null);
  const [name, setName] = useState<string>("");
  const [seoTitle, setSeoTitle] = useState<string>("");
  const [seoDescription, setSeoDescription] = useState<string>("");
  const [rank, setRank] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const handleCategoryEdit = (category: any) => {
    setError("");
    setName(category.name);
    setRank(category.rank);
    setSeoTitle(category.seoTitle || "");
    setSeoDescription(category.seoDescription || "");
    setSelected(category);
    setShowForm(true);
  };

  const handleSubmitCategory = () => {
    setError("");
    if (selected) {
      categoryService
        .update(
          { name, rank, seoTitle, seoDescription, slug: stringToSlug(name) },
          selected.slug
        )
        .then((res) => {
          if (res.data.success) {
            setSelected(res.data.resource);
            refreshCategories();
          } else {
            setError(res.data.error?.message);
          }
        })
        .catch((error) => setError(error.message));
    } else {
      categoryService
        .create({
          name,
          rank,
          seoTitle,
          seoDescription,
          slug: stringToSlug(name),
        })
        .then((res) => {
          if (res.data.success) {
            handleResetSelected();
            refreshCategories();
          } else {
            setError(res.data.error?.message);
          }
        })
        .catch((error) => setError(error.message));
    }
  };

  const handleCategoryDelete = (category: any) => {
    setError("");
    var answer = window.confirm("Are you sure?");
    if (!answer) return;

    categoryService
      .delete(category.slug)
      .then((res) => {
        if (res.data.success) {
          setSelected(null);
          refreshCategories();
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  };

  const handleResetSelected = () => {
    setError("");
    setName("");
    setSeoTitle("");
    setSeoDescription("");
    setRank(0);
    setSelected(null);
  };

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ width: "100%" }}
    >
      {error && (
        <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
          {error}
        </FormHelperText>
      )}

      {showForm && (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: { xs: "100%", sm: "50%" } }}
        >
          <TextField
            fullWidth
            label="Seo Title"
            value={seoTitle}
            name="seoTitle"
            size="small"
            onBlur={(e) => setSeoTitle(e.target.value)}
            onChange={(e) => setSeoTitle(e.target.value)}
            placeholder={name || "Name as default Seo Title"}
            sx={{ width: "100%" }}
          />

          <TextField
            fullWidth
            label="Seo Description"
            value={seoDescription}
            name="seoDescription"
            size="small"
            multiline
            rows={2}
            onBlur={(e) => setSeoDescription(e.target.value)}
            onChange={(e) => setSeoDescription(e.target.value)}
            sx={{ width: "100%" }}
          />

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              label="Name"
              value={name}
              name="name"
              size="small"
              required
              onBlur={(e) => setName(e.target.value)}
              onChange={(e) => setName(e.target.value)}
              sx={{ width: "50%" }}
            />

            <TextField
              fullWidth
              label="Rank"
              value={rank}
              name="rank"
              size="small"
              type="number"
              onBlur={(e) => setRank(Number(e.target.value))}
              onChange={(e) => setRank(Number(e.target.value))}
              helperText={"By descending order"}
              sx={{ width: "25%" }}
            />

            <Button
              disableElevation
              fullWidth
              sx={{ width: "25%" }}
              onClick={handleSubmitCategory}
            >
              {`${selected ? "Update" : "Create"}`}
            </Button>

            <Button
              disableElevation
              fullWidth
              sx={{ minWidth: "0px", width: "36px" }}
              onClick={handleResetSelected}
            >
              <RestartAltOutlined />
            </Button>
          </Stack>
        </Stack>
      )}

      <Box sx={{ width: "100%" }}>
        <ListItem
          sx={{ width: "fit-content", pl: "0", display: "inline-block" }}
        >
          <Chip icon={null} label="All" />
        </ListItem>
        {categories &&
          categories.map((cat) => (
            <ListItem
              key={cat.slug}
              sx={{
                width: "fit-content",
                paddingLeft: "0",
                display: "inline-block",
              }}
            >
              <Chip
                icon={null}
                label={cat.name}
                onClick={() => handleCategoryEdit(cat)}
                onDelete={() => handleCategoryDelete(cat)}
              />
            </ListItem>
          ))}
      </Box>
    </Stack>
  );
};

export default Categories;
