import { BiographyDataType, BiographyType } from "../../interfaces/services";
import http from "../http";

class BiographyService {
  getDefault() {
    return http.get<BiographyDataType>(`/biographies/default`);
  }

  create(data: BiographyType) {
    return http.post<BiographyDataType>("/biographies", data);
  }

  updateDefault(data: BiographyType) {
    return http.put<BiographyDataType>(`/biographies/default`, data);
  }

  deleteDefault() {
    return http.delete<BiographyDataType>(`/biographies/default`);
  }
}

export default new BiographyService();
