import {
  AspectRatio,
  Check,
  DeleteOutline,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Stack,
  TextField,
  MenuItem,
  Button,
  Card,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import imageBlockService from "../../../api/services/imageBlock.service";
import UploadImages from "../../../components/UploadImages";
import { aspectRatios, imageBlocks } from "../../../utilities/constants";
import { Theme, useTheme } from "@mui/material/styles";
import { imgUrl } from "../../../utilities/helpers";
import LoaderBigger from "../../../components/LoaderBigger";
import ColorPicker from "./ColorPicker";
import BlockInfo from "./BlockInfo";
import Vimeo from "@u-wave/react-vimeo";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import Loader from "../../../components/Loader";
import VimeoRatio from "./VimeoRatio";

const NewBlock = ({
  selectedBlock,
  setSelectedBlock,
  refreshBlocks,
  setError,
  setSuccess,
  project,
  rank,
}) => {
  const theme: Theme = useTheme();
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState(null);
  const [previews, setPreviews] = useState([]);
  const [totalSize, setTotalSize] = useState<number>(0);

  const [imageCount, setImageCount] = useState<number>(1);
  const [identifier, setIdentifier] = useState<number>(1);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submittingRanks, setSubmittingRanks] = useState(false);

  const [colors, setColors] = useState<any>({});
  const [ratios, setRatios] = useState<any>({});
  const [ratiosDone, setRatiosDone] = useState<any>({});
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);

  const [showBlockInfo, setShowBlockInfo] = useState<string>("");
  const [showVimeo, setShowVimeo] = useState<boolean>(false);
  const [vimeo, setVimeo] = useState<string>("");
  const [vimeoRatio, setVimeoRatio] = useState<string>("");
  const [showVimeoRatio, setShowVimeoRatio] = useState<string>("");

  useEffect(() => {
    if (selectedBlock && selectedBlock.images) {
      const id = selectedBlock.identifier;
      const { count } = imageBlocks.find((block) => block.value === id);
      setImageCount(count);
      setIdentifier(id);
      setSelectedImages(selectedBlock.images);
    }
  }, [selectedBlock, colors]);

  const handleBlockChange = (id: any) => {
    const uploaded =
      selectedBlock && selectedImages ? selectedImages.length : 0;
    const { count } = imageBlocks.find((block) => block.value === id);
    if (count === 0) {
      setImages((old) => [...old]);
      setPreviews((old) => [...old]);
    } else if (uploaded === 0) {
      setImages((old) => [...old].slice(-count));
      setPreviews((old) => [...old].slice(-count));
    } else if (uploaded < count) {
      setImages((old) => [...old].slice(-(count - uploaded)));
      setPreviews((old) => [...old].slice(-(count - uploaded)));
    } else if (uploaded > count) {
      setImages((old) => [...old].slice(0, count));
      setPreviews((old) => [...old].slice(0, count));
    } else {
      setImages([]);
      setPreviews([]);
    }

    setIdentifier(id);
    setImageCount(count);
  };

  const handleResetForm = () => {
    setError("");
    setSuccess("");
    setImages([]);
    setPreviews([]);
    setIdentifier(1);
    setImageCount(1);
    setSelectedBlock(null);
    setSelectedImages(null);
  };

  function handleCreateImages(block: string) {
    setSubmitting(true);
    if (images.length === 0) {
      setSubmitting(false);
      return;
    }
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("files", image);
    });

    const _colors = previews.map((image) => image.color);
    const _vimeos = previews.map((image) => image.vimeo);
    const _ratios = previews.map((image) => image.vimeoRatio);

    formData.append("colors", JSON.stringify(_colors));
    formData.append("vimeos", JSON.stringify(_vimeos));
    formData.append("ratios", JSON.stringify(_ratios));

    imageBlockService
      .createImages(formData, block)
      .then((res) => {
        if (res.data.success) {
          setImages([]);
          setPreviews([]);
          setSubmitting(false);
          hijackRefreshBlocks();
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.message);
      });
  }

  function handleUpdateImageColor(id: string) {
    setError("");
    const color = colors[id];

    if (!color) return;

    imageBlockService
      .updateImage({ color }, id)
      .then((res) => {
        if (res.data.success) {
          const _colors = Object.assign({}, colors);
          delete _colors[id];
          setColors(_colors);
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  function handleUpdateVimeoRatio(id: string) {
    setError("");
    const vimeoRatio = ratios[id];

    if (!vimeoRatio) return;

    imageBlockService
      .updateImage({ vimeoRatio }, id)
      .then((res) => {
        if (res.data.success) {
          const _ratios = Object.assign({}, ratios);
          delete _ratios[id];
          setRatios(_ratios);

          const _ratiosDone = Object.assign({}, ratiosDone);
          _ratiosDone[id] = vimeoRatio;
          setRatiosDone(_ratiosDone);
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  const handleCloseColorPicker = () => {
    setOpenColorPicker(false);
    setSelectedImage(null);
  };

  const handleOpenColorPicker = (image: any) => {
    setSelectedImage(image);
    setOpenColorPicker(true);
  };

  const hijackRefreshBlocks = () => {
    // handleResetForm();
    refreshBlocks();
  };

  const handleSubmitBlock = async () => {
    setSubmitting(true);
    setError("");
    setSuccess("");
    if (selectedBlock) {
      imageBlockService
        .updateBlock({ identifier, project: project._id }, selectedBlock._id)
        .then((res) => {
          if (res.data.success) {
            handleCreateImages(res.data.resource._id);
            setSuccess("Image block updated");
            hijackRefreshBlocks();
          } else {
            setSubmitting(false);
            setError(res.data.error?.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setError(error.message);
        });
    } else {
      imageBlockService
        .createBlock({ identifier, rank, project: project._id })
        .then((res) => {
          if (res.data.success) {
            setSelectedBlock(res.data.resource);
            handleCreateImages(res.data.resource._id);
            setSuccess("Image block created");
            hijackRefreshBlocks();
          } else {
            setSubmitting(false);
            setError(res.data.error?.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setError(error.message);
        });
    }
  };

  const handleDeleteImage = (image: any) => {
    setSubmitting(true);
    imageBlockService
      .deleteImage(image._id)
      .then((res) => {
        if (res.data.success) {
          setSubmitting(false);
          hijackRefreshBlocks();
        } else {
          setSubmitting(false);
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.message);
      });
  };

  const handleAddVimeo = () => {
    if (!vimeo || !vimeoRatio) return;
    const file = new File(["foo"], "foo.png");
    setPreviews((old) => [...old, { vimeo, color: "#000000", vimeoRatio }]);
    setImages((old) => [...old, file]);
    handleBlockChange(identifier);
  };

  const handleOnDragEndUploaded = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(selectedImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedImages(items);

    const ids = items.map((item: any) => item._id);
    handleUpdateRank(ids);
  };

  const handleUpdateRank = (items: any) => {
    setSubmittingRanks(true);
    imageBlockService
      .updateImageRank(items)
      .then((res) => {
        if (res.data.success) {
          // Success
        } else {
          console.log(res.data.error?.message);
        }
        setSubmittingRanks(false);
      })
      .catch((error) => {
        setSubmittingRanks(false);
        console.log("Error Block Ranks:", error);
      });
  };

  const handleVimeoRatio = (e: any, id: string) => {
    const _ratios = Object.assign({}, ratios);
    _ratios[id] = e.target.value;
    setRatios(_ratios);
  };

  return (
    <>
      {submitting && <LoaderBigger />}
      {submittingRanks && <Loader />}
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: { xs: "100%", sm: "60%" } }}
        >
          <TextField
            fullWidth
            select
            label="Image Block"
            name="block"
            size="small"
            value={identifier}
            onChange={(e) => handleBlockChange(e.target.value)}
            helperText={`Max ${imageCount ? imageCount : "infinite"} image(s)`}
            sx={{
              width: "20%",
              "& .MuiFormHelperText-filled": {
                marginLeft: 0,
                marginRight: 0,
                fontSize: "11px",
                color: "#909090",
              },
            }}
          >
            {imageBlocks.map((block) => (
              <MenuItem key={block.value} value={block.value}>
                {block.value}
              </MenuItem>
            ))}
          </TextField>

          <Button
            onClick={handleSubmitBlock}
            disabled={totalSize > 20_000_000}
            sx={{
              p: "8px",
              width: "20%",
              backgroundColor: `${totalSize > 20_000_000 ? "red" : "#43a310"}`,
            }}
          >
            {selectedBlock ? "Update" : "Create"}
          </Button>

          <Button
            onClick={() => setShowVimeo((old) => !old)}
            sx={{ p: "8px", backgroundColor: "#4d83d1" }}
          >
            {`${!showVimeo ? "Add" : "Hide Add"} Vimeo`}
          </Button>

          <Button onClick={handleResetForm} sx={{ p: "8px" }}>
            Reset Form
          </Button>

          <Box>
            <IconButton
              onClick={() => setShowBlockInfo("yes")}
              onMouseEnter={() => setShowBlockInfo("yes")}
            >
              <InfoOutlined />
            </IconButton>
            {showBlockInfo && (
              <BlockInfo
                identifier={identifier}
                setShowBlockInfo={setShowBlockInfo}
              />
            )}
          </Box>

          <Box>
            <IconButton
              onClick={() => setShowVimeoRatio("yes")}
              onMouseEnter={() => setShowVimeoRatio("yes")}
            >
              <AspectRatio />
            </IconButton>
            {showVimeoRatio && (
              <VimeoRatio setShowVimeoRatio={setShowVimeoRatio} />
            )}
          </Box>
        </Stack>

        {showVimeo && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: "60%" }}
          >
            <TextField
              fullWidth
              label="Vimeo"
              value={vimeo}
              name="vimeo"
              size="small"
              onBlur={(e) => setVimeo(e.target.value)}
              onChange={(e) => setVimeo(e.target.value)}
              sx={{
                width: "65%",
                fontSize: "11px",
                mr: "15px",
                "& .MuiInputBase-input": {
                  p: "10.5px 14px",
                },
              }}
            />

            <TextField
              fullWidth
              select
              label="Vimeo Ratio"
              name="vimeoRatio"
              size="small"
              value={vimeoRatio}
              onChange={(e) => setVimeoRatio(e.target.value)}
              helperText={``}
              sx={{
                width: "15%",
                mr: "15px",
                "& .MuiFormHelperText-filled": {
                  marginLeft: 0,
                  marginRight: 0,
                  fontSize: "11px",
                  color: "#909090",
                },
              }}
            >
              {aspectRatios.map((ratio) => (
                <MenuItem key={ratio} value={ratio}>
                  {ratio}
                </MenuItem>
              ))}
            </TextField>

            <Button
              onClick={handleAddVimeo}
              sx={{ p: "8px 6px", width: "20%", backgroundColor: "#43a310" }}
            >
              Add
            </Button>
          </Stack>
        )}

        <Box sx={{ width: "100%" }}>
          <UploadImages
            totalSize={totalSize}
            setTotalSize={setTotalSize}
            inputBoxSx={{ width: { xs: "100%", sm: "60%" } }}
            imageHeight="120px"
            multiple
            max={imageCount}
            required
            handleBlockChange={handleBlockChange}
            identifier={identifier}
            files={images}
            setFiles={setImages}
            previews={previews}
            setPreviews={setPreviews}
          />
        </Box>

        {selectedImages && (
          <>
            <Box
              sx={{
                backgroundColor: "#f3f2f2",
                height: "1px",
                width: "100%",
                m: "8px 0",
              }}
            />

            <DragDropContext onDragEnd={handleOnDragEndUploaded}>
              <Droppable droppableId="uploaded-images" direction="horizontal">
                {(provided) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{ mt: "10px", width: "100%" }}
                  >
                    {selectedImages.map((image, index) => (
                      <Draggable
                        key={image._id}
                        draggableId={image._id}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              display: "inline-block",
                              width: "fit-content",
                            }}
                          >
                            <Stack
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                            >
                              <Card
                                id={image._id}
                                elevation={0}
                                sx={{
                                  padding: "0",
                                  margin: "4px",
                                  borderRadius: "2px",
                                  position: "relative",
                                  width: "fit-content",
                                  maxHeight: "120px",
                                }}
                              >
                                {image.vimeo && (
                                  <Vimeo
                                    video={image.vimeo}
                                    autoplay
                                    muted
                                    width={187}
                                    height={120}
                                  />
                                )}
                                {!image.vimeo && (
                                  <CardMedia
                                    sx={{
                                      height: "120px",
                                      width: "fit-content",
                                      display: "inline-block",
                                    }}
                                    component="img"
                                    src={imgUrl(image.slug)}
                                    alt={image.alt}
                                  />
                                )}

                                <IconButton
                                  onClick={() => handleDeleteImage(image)}
                                  sx={{
                                    color: theme.colors.error,
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                  }}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Card>

                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={0.8}
                                sx={{ m: "4px" }}
                              >
                                <Box
                                  onClick={() => handleOpenColorPicker(image)}
                                  sx={{
                                    backgroundColor:
                                      colors[image._id] || image.color,
                                    height: "28px",
                                    width: "28px",
                                    cursor: "pointer",
                                    borderRadius: "4px",
                                    border: "1px solid #e6e6e6",
                                  }}
                                />
                                <Typography>{image.color}</Typography>
                                {colors[image._id] && (
                                  <Button
                                    onClick={() =>
                                      handleUpdateImageColor(image._id)
                                    }
                                    sx={{ minWidth: "auto" }}
                                  >
                                    <Check
                                      sx={{ height: "16px", width: "16px" }}
                                    />
                                  </Button>
                                )}
                              </Stack>

                              {image.vimeo && (
                                <Stack
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={0.8}
                                  sx={{ m: "12px 4px 4px" }}
                                >
                                  <TextField
                                    fullWidth
                                    select
                                    label="Ratio"
                                    name="vimeoRatio"
                                    size="small"
                                    value={
                                      ratios[image._id] ||
                                      ratiosDone[image._id] ||
                                      image.vimeoRatio
                                    }
                                    onChange={(e) =>
                                      handleVimeoRatio(e, image._id)
                                    }
                                    helperText={``}
                                    sx={{
                                      width: "95px",
                                      "& .MuiFormHelperText-filled": {
                                        marginLeft: 0,
                                        marginRight: 0,
                                        fontSize: "11px",
                                        color: "#909090",
                                      },
                                      "& .MuiSelect-select": {
                                        p: "4.5px 14px",
                                      },
                                    }}
                                  >
                                    {aspectRatios.map((ratio) => (
                                      <MenuItem key={ratio} value={ratio}>
                                        {ratio}
                                      </MenuItem>
                                    ))}
                                  </TextField>

                                  {ratios[image._id] && (
                                    <Button
                                      onClick={() =>
                                        handleUpdateVimeoRatio(image._id)
                                      }
                                      sx={{ minWidth: "auto" }}
                                    >
                                      <Check
                                        sx={{ height: "16px", width: "16px" }}
                                      />
                                    </Button>
                                  )}
                                </Stack>
                              )}
                            </Stack>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </Stack>

      {selectedImage && (
        <ColorPicker
          colors={colors}
          setColors={setColors}
          image={selectedImage}
          open={openColorPicker}
          handleClose={handleCloseColorPicker}
        />
      )}
    </>
  );
};

export default NewBlock;
