import { createReducer } from "@reduxjs/toolkit";
import { setPreviousPath } from "../actions/previousPathActions";

interface PreviousPath {
  previousPath: string;
}

const initialState: PreviousPath = { previousPath: "" };

const previousPathReducer = createReducer(initialState, (builder) => {
  builder.addCase(setPreviousPath, (state, action) => {
    state.previousPath = action.payload;
  });
});

export default previousPathReducer;
