import { Close } from "@mui/icons-material";
import {
  Box,
  Stack,
  Card,
  CardMedia,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import UploadColorPicker from "./UploadColorPicker";
import Vimeo from "@u-wave/react-vimeo";
import { getTotalSize } from "../utilities/helpers";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { aspectRatios } from "../utilities/constants";

const useForceUpdate = () => {
  const state = useState(false);
  return () => state[1]((s) => !s);
};

const UploadImages = ({
  inputBoxSx,
  imageHeight,
  multiple,
  max,
  required,
  files,
  setFiles,
  handleBlockChange,
  identifier,
  previews,
  setPreviews,
  totalSize,
  setTotalSize,
}) => {
  const forceUpdate = useForceUpdate();
  const [selectedImage, setSelectedImage] = useState<boolean>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);

  const loadFiles = (e) => {
    const fileObjects = e.target.files;
    for (let i = 0; i < fileObjects.length; i += 1) {
      const url = URL.createObjectURL(fileObjects[i]);
      const color = "#000000";
      const file = fileObjects[i];

      // If !max then allow infinite
      if (!multiple) {
        setPreviews([{ url, color }]);
        setFiles([file]);
      } else if (!max) {
        setPreviews((old) => [...old, { url, color }]);
        setFiles((old) => [...old, file]);
      } else {
        setPreviews((old) => [...old, { url, color }].slice(-max));
        setFiles((old) => [...old, file].slice(-max));
      }

      setTotalSize((old) => old + file.size);
    }

    forceUpdate();
    handleBlockChange(identifier);
  };

  const removeFile = (index) => {
    files.splice(index, 1);
    previews.splice(index, 1);

    const input = document.getElementById(
      "choose-files-input"
    ) as HTMLInputElement;
    input.value = "";

    forceUpdate();

    setFiles([...files]);
    setPreviews([...previews]);
    setTotalSize(getTotalSize([...files]));
  };

  const handleCloseColorPicker = () => {
    setOpenColorPicker(false);
    setSelectedImage(null);
  };

  const handleOpenColorPicker = (image: any, index: number) => {
    setSelectedImage(image);
    setSelectedIndex(index);
    setOpenColorPicker(true);
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const fileItems = Array.from(files);
    const [reorderedFileItem] = fileItems.splice(result.source.index, 1);
    fileItems.splice(result.destination.index, 0, reorderedFileItem);

    const previewItems = Array.from(previews);
    const [reorderedPreviewItem] = previewItems.splice(result.source.index, 1);
    previewItems.splice(result.destination.index, 0, reorderedPreviewItem);

    setFiles(fileItems);
    setPreviews(previewItems);
  };

  const handleVimeoRatio = (e: any, index: number) => {
    previews[index].vimeoRatio = e.target.value;
    handleBlockChange(identifier);
  };

  return (
    <>
      <Box className="files-upload-container" sx={{ width: "100%" }}>
        <Box
          className="choose-files"
          role="button"
          tabIndex={0}
          sx={{
            border: "2px dashed #b9b9b9",
            borderRadius: "2px",
            position: "relative",
            display: "inline-block",
            ...inputBoxSx,
          }}
        >
          <Typography
            style={{
              padding: "16.5px 14px",
              textAlign: "center",
              color: `${totalSize > 20_000_000 ? "red" : "#000000"}`,
            }}
          >
            Drag or Click to Add Images (Total Max 20MB) | Total Added Size:{" "}
            {(totalSize / (1024 * 1024)).toFixed(2)} MB
          </Typography>
          <input
            type="file"
            id="choose-files-input"
            onChange={loadFiles}
            multiple={multiple}
            accept=".jpg, .jpeg, .png, .gif"
            required={required}
            style={{
              top: 0,
              position: "absolute",
              width: "100%",
              padding: "16.5px 14px",
              opacity: 0,
              cursor: "pointer",
            }}
          />
        </Box>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="added-images" direction="horizontal">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ mt: "10px" }}
              >
                {previews.map((image, index) => (
                  <Draggable
                    key={image.url}
                    draggableId={image.url}
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{ display: "inline-block", width: "fit-content" }}
                      >
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Card
                            elevation={0}
                            sx={{
                              padding: "0",
                              margin: "4px",
                              borderRadius: "2px",
                              position: "relative",
                              width: "fit-content",
                              display: "inline-block",
                              maxHeight: imageHeight || "80px",
                            }}
                          >
                            {image.vimeo && (
                              <Vimeo
                                video={image.vimeo}
                                autoplay
                                muted
                                width={187}
                                height={120}
                              />
                            )}
                            {!image.vimeo && (
                              <CardMedia
                                id={image.url}
                                sx={{
                                  height: imageHeight || "80px",
                                  width: "fit-content",
                                  display: "inline-block",
                                }}
                                component="img"
                                src={image.url}
                                alt={image.url}
                              />
                            )}
                            <Close
                              onClick={() => removeFile(index)}
                              sx={{
                                position: "absolute",
                                top: "2px",
                                right: "2px",
                                padding: 0,
                                cursor: "pointer",
                                color: "#f1f1f1",
                                fontSize: "17px",
                                backgroundColor: "rgb(82 82 82 / 30%)",
                                borderRadius: "100%",
                              }}
                            />
                          </Card>
                          <Typography
                            sx={{
                              m: "0 4px",
                              fontSize: "11px",
                              color: "#383838",
                            }}
                          >
                            {`Size: ${(
                              files[index].size /
                              (1024 * 1024)
                            ).toFixed(2)} MB`}
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={0.8}
                            sx={{ m: "4px", maxWidth: "187px" }}
                          >
                            <Box
                              onClick={() =>
                                handleOpenColorPicker(image.color, index)
                              }
                              sx={{
                                backgroundColor: image.color,
                                height: "28px",
                                width: "28px",
                                cursor: "pointer",
                                borderRadius: "4px",
                                border: "1px solid #e6e6e6",
                              }}
                            />
                            <Typography>{image.color}</Typography>

                            {image.vimeo && (
                              <TextField
                                fullWidth
                                select
                                label="Ratio"
                                name="vimeoRatio"
                                size="small"
                                value={image.vimeoRatio}
                                onChange={(e) => handleVimeoRatio(e, index)}
                                helperText={``}
                                sx={{
                                  width: "95px",
                                  "& .MuiFormHelperText-filled": {
                                    marginLeft: 0,
                                    marginRight: 0,
                                    fontSize: "11px",
                                    color: "#909090",
                                  },
                                  "& .MuiSelect-select": {
                                    p: "4.5px 14px",
                                  },
                                }}
                              >
                                {aspectRatios.map((ratio) => (
                                  <MenuItem key={ratio} value={ratio}>
                                    {ratio}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                  </Draggable>
                ))}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      {selectedImage && (
        <UploadColorPicker
          index={selectedIndex}
          previews={previews}
          setPreviews={setPreviews}
          image={selectedImage}
          open={openColorPicker}
          handleClose={handleCloseColorPicker}
        />
      )}
    </>
  );
};

export default UploadImages;
