export default function themeSizes() {
  return {
    35: "2.1875rem",
    34: "2.125rem",
    33: "2.0625rem",
    32: "2rem",
    31: "1.9375rem",
    30: "1.875rem",
    29: "1.8125rem",
    28: "1.75rem",
    27: "1.6875rem",
    26: "1.625rem",
    25: "1.5625rem",
    24: "1.5rem",
    23: "1.4375rem",
    22: "1.375rem",
    21: "1.3125rem",
    20: "1.25rem",
    19: "1.1875rem",
    18: "1.125rem",
    17: "1.0625rem",
    16: "1rem",
    15: "0.9375rem",
    14: "0.875rem",
    13: "0.8125rem",
    12: "0.75rem",
    11: "0.6875rem",
    10: "0.625rem",
    9: "0.563rem",
    8: "0.5rem",
    7: "0.438rem",
    6: "0.375rem",
  };
}
