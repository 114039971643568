/* eslint-disable react/jsx-props-no-spreading */
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  FormHelperText,
  ListItem,
  Chip,
  Box,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Theme, useTheme } from "@mui/material/styles";
import RichEditor from "../../components/RichEditor";
import contactService from "../../api/services/contact.service";
import { convertEditorToRaw, getRawContent } from "../../utilities/helpers";

const FormContact = ({
  contact,
  setContact,
  open,
  handleClose,
  refreshContact,
}) => {
  const theme: Theme = useTheme();

  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [seoTitle, setSeoTitle] = useState<string>("");
  const [seoDescription, setSeoDescription] = useState<string>("");
  const [menu, setMenu] = useState<Array<any>>([]);
  const [menuName, setMenuName] = useState<string>("");
  const [menuUrl, setMenuUrl] = useState<string>("");
  const [menuRank, setMenuRank] = useState<number>(0);
  const [imprint, setImprint] = useState(EditorState.createEmpty());
  const [data, setData] = useState(EditorState.createEmpty());
  const [submitting, setSubmitting] = useState<boolean>(false);

  const getEditorState = (string: string, setResource: any) => {
    const raw = getRawContent(string);
    const editorState = EditorState.createWithContent(raw);
    setResource(editorState);
  };

  useEffect(() => {
    setSuccess("");
    setError("");
    if (!contact) return;
    setPhone(`${contact.phone || ""}`);
    setEmail(`${contact.email || ""}`);
    setSeoTitle(`${contact.seoTitle || ""}`);
    setSeoDescription(`${contact.seoDescription || ""}`);
    setMenu(contact.menu || []);
    getEditorState(contact.imprint, setImprint);
    getEditorState(contact.data, setData);
  }, [contact]);

  function createContact(values: any) {
    contactService
      .create(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Contact created");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  function updateContact(values: any) {
    contactService
      .updateDefault(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Contact updated");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  const handleSubmitContact = () => {
    setSuccess("");
    setError("");
    setSubmitting(true);
    const params = {
      email,
      phone,
      seoTitle,
      seoDescription,
      menu,
      imprint: convertEditorToRaw(imprint),
      data: convertEditorToRaw(data),
    };

    if (contact) {
      updateContact(params);
    } else {
      createContact(params);
    }
  };

  const handleDeleteMenu = (name: string) => {
    setMenu((items) => items.filter((item) => item.name !== name));
  };

  const handleAddMenu = () => {
    const name = menuName.trim();
    const url = menuUrl.trim();
    if (!name) return;

    const included = menu.find((item) => item.name === name);
    if (included) return;

    const current = { name, url, rank: menuRank };

    setMenu((old) => [...old, current]);
    setMenuName("");
    setMenuUrl("");
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} scroll="body">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          p: { xs: "10px 15px 0", sm: "20px 24px 0" },
          mb: "20px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            disableElevation
            disabled={submitting}
            fullWidth
            onClick={handleSubmitContact}
            sx={{ width: "30%" }}
          >
            Update Contact
          </Button>

          <Typography
            sx={{
              fontWeight: "700",
              fontSize: theme.size[15],
              color: theme.colors.grey700,
            }}
          >
            Contact Form
          </Typography>
        </Stack>

        <IconButton
          style={{ color: "gray" }}
          onClick={() => {
            refreshContact();
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Stack>

      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          {error && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
              {error}
            </FormHelperText>
          )}

          {success && (
            <FormHelperText sx={{ color: theme.colors.success, pb: "15px" }}>
              {success}
            </FormHelperText>
          )}

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              label="Email"
              value={email}
              name="email"
              size="small"
              onBlur={(e) => setEmail(e.target.value)}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: "65%" }}
            />

            <TextField
              fullWidth
              label="Phone"
              value={phone}
              name="phone"
              size="small"
              onBlur={(e) => setPhone(e.target.value)}
              onChange={(e) => setPhone(e.target.value)}
              sx={{ width: "35%" }}
            />
          </Stack>

          <Typography sx={{ fontWeight: 600, fontSize: theme.size[16] }}>
            Seo
          </Typography>

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              label="Seo Title"
              value={seoTitle}
              name="seoTitle"
              size="small"
              onBlur={(e) => setSeoTitle(e.target.value)}
              onChange={(e) => setSeoTitle(e.target.value)}
              placeholder="Contact"
              sx={{ width: "100%" }}
            />

            <TextField
              fullWidth
              label="Seo Description"
              value={seoDescription}
              name="seoDescription"
              size="small"
              multiline
              rows={2}
              onBlur={(e) => setSeoDescription(e.target.value)}
              onChange={(e) => setSeoDescription(e.target.value)}
              sx={{ width: "100%" }}
            />
          </Stack>

          <Typography sx={{ fontWeight: 600, fontSize: theme.size[16] }}>
            Bottom Left Menus
          </Typography>

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <TextField
                fullWidth
                label="Menu Name *"
                value={menuName}
                name="name"
                size="small"
                onBlur={(e) => setMenuName(e.target.value)}
                onChange={(e) => setMenuName(e.target.value)}
                sx={{ width: "70%" }}
              />

              <TextField
                fullWidth
                label="Menu Rank"
                value={menuRank}
                name="rank"
                size="small"
                type="number"
                onBlur={(e) => setMenuRank(Number(e.target.value))}
                onChange={(e) => setMenuRank(Number(e.target.value))}
                sx={{ width: "30%" }}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <TextField
                fullWidth
                label="Menu Link"
                value={menuUrl}
                name="url"
                size="small"
                onBlur={(e) => setMenuUrl(e.target.value)}
                onChange={(e) => setMenuUrl(e.target.value)}
                sx={{ width: "70%" }}
              />

              <Button onClick={handleAddMenu} sx={{ width: "30%" }}>
                Add Menu
              </Button>
            </Stack>
          </Stack>

          <Box sx={{ width: "100%" }}>
            {menu.map((men) => (
              <ListItem
                key={men.name}
                sx={{
                  width: "100%",
                  pl: "0",
                  pr: "0",
                }}
              >
                <Chip
                  icon={null}
                  label={
                    <Box
                      // onClick={() => {
                      //   setName(men.name);
                      //   setUrl(men.url);
                      //   setUpdate(true);
                      //   setOldClient(men);
                      // }}
                      sx={{ width: "100%" }}
                    >
                      <Typography sx={{ fontWeight: 700 }}>
                        {men.name}
                      </Typography>
                      <Typography>Rank: {men.rank}</Typography>
                      <Typography>{men.url}</Typography>
                    </Box>
                  }
                  onDelete={() => handleDeleteMenu(men.name)}
                  sx={{
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    p: "5px 9px",
                    "& .MuiChip-label": {
                      width: "95%",
                      overflow: "auto",
                      textOverflow: "initial",
                      p: "0",
                      mr: "10px",
                      whiteSpace: "normal",
                    },
                  }}
                />
              </ListItem>
            ))}
          </Box>

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: theme.size[16] }}>
              Imprint
            </Typography>
            <RichEditor
              editorState={imprint}
              setEditorState={setImprint}
              placeholder="Imprint Content..."
            />
          </Stack>

          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%", mt: "10px" }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: theme.size[16] }}>
              Data
            </Typography>
            <RichEditor
              editorState={data}
              setEditorState={setData}
              placeholder="Data Content..."
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FormContact;
