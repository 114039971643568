import { Button, Stack, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { menuItems } from "../../utilities/constants";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Header = () => {
  const theme: Theme = useTheme();
  const pathname = useLocation().pathname;
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ width: "100%" }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ width: "100%" }}
          >
            <Link to="/">
              <Typography
                sx={{
                  ...theme.typography.mainMenu,
                }}
              >
                DORIS HIMMELBAUER
              </Typography>
            </Link>

            {menuItems.map((menu) => (
              <Link key={menu.name} to={menu.path}>
                <Typography
                  sx={{
                    ...theme.typography.mainMenu,
                    backgroundColor:
                      pathname === menu.path ? "#eaeaea" : "#fff",
                  }}
                >
                  {menu.name}
                </Typography>
              </Link>
            ))}
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={3}
            sx={{ width: "auto" }}
          >
            {!isAuthenticated && (
              <Button onClick={() => loginWithRedirect()}>Login</Button>
            )}

            {isAuthenticated && (
              <Button
                onClick={() =>
                  logout({
                    returnTo: window.location.origin + "/login",
                  })
                }
              >
                Logout
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
