import { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Button,
  IconButton,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import Loader from "../../components/Loader";
import projectService from "../../api/services/project.service";
import FormProject from "./FormProject";
import categoryService from "../../api/services/category.service";
import Categories from "./Categories";
import {
  CollectionsOutlined,
  DeleteOutline,
  DriveFileRenameOutline,
  RefreshOutlined,
  ContentCopyOutlined,
  Link,
  PreviewOutlined,
} from "@mui/icons-material";
import FormBlocks from "./ImageBlocks/FormBlocks";
import CategoryMenu from "./CategoryMenu";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { CLIENT_BASE_URL } from "../../utilities/constants";
import FormSeoProjects from "./FormSeoProjects";
import FormPreviewImage from "./FormPreviewImage";

const Projects: React.FC = () => {
  const theme: Theme = useTheme();
  const [projects, setProjects] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [categories, setCategories] = useState(null);
  const [openProject, setOpenProject] = useState<boolean>(false);
  const [openCategory, setOpenCategory] = useState<boolean>(false);
  const [openFormBlocks, setOpenFormBlocks] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [error, setError] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [submittingRanks, setSubmittingRanks] = useState(false);
  const [openSeo, setOpenSeo] = useState<any>(false);
  const [openFormPreview, setOpenFormPreview] = useState<any>(false);

  const handleCategoryFilter = (cat, projs) => {
    setSelectedCategory(cat);
    if (projs) {
      if (!cat) {
        setFiltered(projs);
        return;
      }

      let _filtered = projs.filter((project) => project.category === cat._id);

      _filtered = _filtered.sort((a, b) => a.categoryRank - b.categoryRank);
      setFiltered(_filtered);
    }
  };

  const initializeCategories = () => {
    categoryService
      .getAll()
      .then((res) => {
        if (res.data.success) {
          setCategories(res.data.resource);
        } else {
          console.log(res.data.error?.message);
        }
      })
      .catch((error) => console.log("Error Categories:", error));
  };

  function initializeProjects() {
    projectService
      .getAllShallow()
      .then((res) => {
        if (res.data.success) {
          setProjects(res.data.resource);
          setFiltered(res.data.resource);
          handleCategoryFilter(selectedCategory, res.data.resource);
        } else {
          console.log(res.data.error?.message);
        }
      })
      .catch((error) => console.log("Error Projects:", error));
  }

  useEffect(() => {
    initializeCategories();
    initializeProjects();
  }, []);

  const handleGetCategory = (id: string) => {
    if (!categories) return null;
    const category = categories.find((cat) => cat._id === id);
    if (category) return category.name;
    else return null;
  };

  const handleOpenProjectForm = () => {
    setOpenProject((old) => !old);
  };

  const handleOpenCategoryForm = () => {
    setOpenCategory((old) => !old);
  };

  const refreshProjects = () => {
    setError("");
    initializeProjects();
  };

  const refreshCategories = () => {
    initializeCategories();
  };

  const handleEditProject = (project: any) => {
    setSelectedProject(project);
    setOpenProject((old) => !old);
  };

  const handleOpenFormBlocks = () => {
    setOpenFormBlocks((old) => !old);
  };

  const handleOpenFormPreview = () => {
    setOpenFormPreview((old) => !old);
  };

  const handleDeleteProject = (project: any) => {
    setError("");
    var answer = window.confirm("Are you sure?");
    if (!answer) return;

    projectService
      .delete(project.slug)
      .then((res) => {
        if (res.data.success) {
          refreshProjects();
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  };

  const handleDuplicateProject = (project: any) => {
    setError("");
    var answer = window.confirm("Are you sure?");
    if (!answer) return;

    projectService
      .duplicate(project.slug)
      .then((res) => {
        if (res.data.success) {
          initializeProjects();
        } else {
          console.log(res.data.error?.message);
        }
      })
      .catch((error) => console.log("Error Projects:", error));
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    let items = Array.from(filtered);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFiltered(items);

    const ids = items.map((item: any) => item._id);
    handleUpdateProjectsRank(ids);
  };

  const handleUpdateProjectsRank = (items: any) => {
    setSubmittingRanks(true);
    if (selectedCategory) {
      updateProjectCategoryRank(items);
    } else {
      updateProjectRank(items);
    }
  };

  function updateProjectRank(items: any) {
    projectService
      .updateRank(items)
      .then((res) => {
        if (res.data.success) {
          // Success
        } else {
          console.log(res.data.error?.message);
        }
        setSubmittingRanks(false);
      })
      .catch((error) => {
        setSubmittingRanks(false);
        console.log("Error Projects:", error);
      });
  }

  function updateProjectCategoryRank(items: any) {
    projectService
      .updateCategoryRank(items)
      .then((res) => {
        if (res.data.success) {
          // Success
        } else {
          console.log(res.data.error?.message);
        }
        setSubmittingRanks(false);
      })
      .catch((error) => {
        setSubmittingRanks(false);
        console.log("Error Project Ranks:", error);
      });
  }

  const handleCopyProjectChange = () => {};

  const handleCopyProjectLink = (id: string) => {
    // Get the text field
    const copyText = document.getElementById(`link-${id}`) as HTMLInputElement;

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  };

  const handleOpenFormSeo = () => {
    setOpenSeo((old) => !old);
  };

  if (!projects || !filtered) return <Loader />;

  return (
    <>
      {submittingRanks && <Loader />}

      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          width: "100%",
          mt: "65px",
          height: "calc(100vh - 65px)",
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={{ xs: 0.8, sm: 0.8 }}
          sx={{ width: "100%" }}
        >
          <Button
            onClick={handleOpenFormSeo}
            sx={{ width: { xs: "100%", sm: "200px" }, mb: "20px" }}
          >
            Edit Projects Page Seo
          </Button>

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{
              width: "100%",
              pb: "20px",
            }}
          >
            <Button
              onClick={handleOpenCategoryForm}
              sx={{ width: { xs: "100%", sm: "200px" } }}
            >
              New Category
            </Button>
            <Button onClick={refreshCategories} sx={{ minWidth: "32px" }}>
              <RefreshOutlined />
            </Button>
          </Stack>

          <Categories
            categories={categories}
            showForm={openCategory}
            setShowForm={setOpenCategory}
            refreshCategories={refreshCategories}
          />
        </Stack>

        <Box
          sx={{
            backgroundColor: "#f3f2f2",
            height: "1px",
            width: "100%",
            m: "20px 0",
          }}
        />

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          sx={{
            width: "100%",
            pb: "20px",
          }}
        >
          <Button
            onClick={handleOpenProjectForm}
            sx={{ width: { xs: "100%", sm: "200px" } }}
          >
            New Project
          </Button>

          <Button onClick={refreshProjects} sx={{ minWidth: "32px" }}>
            <RefreshOutlined />
          </Button>
        </Stack>

        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ width: "100%", mt: "15px" }}
        >
          {error && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "10px" }}>
              {error}
            </FormHelperText>
          )}

          <CategoryMenu
            projects={projects}
            handleFilter={handleCategoryFilter}
            categories={categories}
            category={selectedCategory}
          />

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              width: "100%",
              backgroundColor: theme.colors.grey200,
              padding: "8px",
              borderRadius: "4px",
              mb: "10px",
            }}
          >
            <Typography
              component="div"
              sx={{
                width: { xs: "auto", sm: "320px" },
                fontSize: { xs: theme.size[10], sm: theme.size[12] },
                fontWeight: 400,
                lineHeight: {
                  xs: theme.size[13],
                  sm: theme.size[22],
                },
              }}
            >
              Title
            </Typography>

            <Typography
              component="div"
              sx={{
                width: { xs: "auto", sm: "160px" },
                fontSize: { xs: theme.size[10], sm: theme.size[12] },
                fontWeight: 400,
                lineHeight: {
                  xs: theme.size[13],
                  sm: theme.size[22],
                },
              }}
            >
              Client
            </Typography>

            <Typography
              component="div"
              sx={{
                width: { xs: "auto", sm: "160px" },
                fontSize: { xs: theme.size[10], sm: theme.size[12] },
                fontWeight: 400,
                lineHeight: {
                  xs: theme.size[13],
                  sm: theme.size[22],
                },
              }}
            >
              Category
            </Typography>

            <Typography
              component="div"
              sx={{
                width: { xs: "auto", sm: "100px" },
                fontSize: { xs: theme.size[10], sm: theme.size[12] },
                fontWeight: 400,
                lineHeight: {
                  xs: theme.size[13],
                  sm: theme.size[22],
                },
              }}
            >
              Blocks
            </Typography>

            <Box />
            <Box />
            <Box />
            <Box />
          </Stack>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="projects">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {filtered.map((project, index) => (
                    <Draggable
                      key={project.slug}
                      draggableId={project.slug}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            m: "10px 0",
                            p: "5px 0",
                            backgroundColor: "white",
                            borderRadius: "6px",
                            border: "1.5px solid #efefef",
                            "&:hover": {
                              backgroundColor: "#efefef",
                            },
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: "100%", padding: "0 8px" }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={{ xs: 2, sm: 0 }}
                              sx={{ width: "auto%" }}
                            >
                              <Box
                                onClick={() => handleEditProject(project)}
                                sx={{
                                  width: { xs: "auto", sm: "320px" },
                                  cursor: "pointer",
                                }}
                              >
                                <Typography
                                  component="div"
                                  sx={{
                                    width: { xs: "auto", sm: "190px" },
                                    fontSize: {
                                      xs: theme.size[10],
                                      sm: theme.size[12],
                                    },
                                    fontWeight: { xs: 400, sm: 400 },
                                    textTransform: "uppercase",
                                    lineHeight: {
                                      xs: theme.size[13],
                                      sm: theme.size[22],
                                    },
                                  }}
                                >
                                  {project.title}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  width: { xs: "auto", sm: "160px" },
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditProject(project)}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: theme.size[11],
                                      sm: theme.size[12],
                                    },
                                    fontWeight: 400,
                                    lineHeight: {
                                      xs: theme.size[13],
                                      sm: theme.size[22],
                                    },
                                  }}
                                >
                                  {project.client}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  width: { xs: "auto", sm: "160px" },
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditProject(project)}
                              >
                                <Typography
                                  component="div"
                                  sx={{
                                    width: { xs: "100%", sm: "160px" },
                                    fontSize: {
                                      xs: theme.size[10],
                                      sm: theme.size[12],
                                    },
                                    fontWeight: { xs: 400, sm: 400 },
                                    lineHeight: {
                                      xs: theme.size[13],
                                      sm: theme.size[22],
                                    },
                                  }}
                                >
                                  {handleGetCategory(project?.category)}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  width: { xs: "auto", sm: "100px" },
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditProject(project)}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: theme.size[11],
                                      sm: theme.size[12],
                                    },
                                    fontWeight: 400,
                                    lineHeight: {
                                      xs: theme.size[13],
                                      sm: theme.size[22],
                                    },
                                  }}
                                >
                                  {project.blocksCount}
                                </Typography>
                              </Box>
                            </Stack>

                            <Tooltip
                              title="Set Project Preview"
                              placement="top"
                            >
                              <IconButton
                                onClick={() => {
                                  setSelectedProject(project);
                                  handleOpenFormPreview();
                                }}
                                sx={{
                                  color: project.preview
                                    ? "green"
                                    : "#0000008a",
                                }}
                              >
                                <PreviewOutlined />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Edit Project" placement="top">
                              <IconButton
                                onClick={() => handleEditProject(project)}
                              >
                                <DriveFileRenameOutline />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Edit Blocks" placement="top">
                              <IconButton
                                onClick={() => {
                                  setSelectedProject(project);
                                  handleOpenFormBlocks();
                                }}
                              >
                                <CollectionsOutlined />
                              </IconButton>
                            </Tooltip>

                            <input
                              style={{ display: "none" }}
                              type="text"
                              onChange={handleCopyProjectChange}
                              value={`${CLIENT_BASE_URL}/project/${project.slug}`}
                              id={`link-${project._id}`}
                            ></input>

                            <Tooltip title="Copy Project Link" placement="top">
                              <IconButton
                                onClick={() => {
                                  handleCopyProjectLink(project._id);
                                }}
                              >
                                <Link />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Duplicate Project" placement="top">
                              <IconButton
                                onClick={() => {
                                  handleDuplicateProject(project);
                                }}
                              >
                                <ContentCopyOutlined />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Project" placement="top">
                              <IconButton
                                onClick={() => handleDeleteProject(project)}
                                sx={{ color: theme.colors.error }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
      </Stack>

      <FormProject
        rank={projects.length}
        categoryRank={filtered.length}
        categories={categories}
        project={selectedProject}
        setProject={setSelectedProject}
        refreshProjects={refreshProjects}
        open={openProject}
        handleClose={handleOpenProjectForm}
      />

      {selectedProject && (
        <>
          <FormBlocks
            project={selectedProject}
            setProject={setSelectedProject}
            open={openFormBlocks}
            handleClose={handleOpenFormBlocks}
          />

          <FormPreviewImage
            project={selectedProject}
            setProject={setSelectedProject}
            open={openFormPreview}
            handleClose={handleOpenFormPreview}
            refreshProjects={refreshProjects}
          />
        </>
      )}

      <FormSeoProjects open={openSeo} handleClose={handleOpenFormSeo} />
    </>
  );
};

export default Projects;
