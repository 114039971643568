import {
  ContactDataType,
  ContactType,
  DeleteDataType,
} from "../../interfaces/services";
import http from "../http";

class ContactService {
  getDefault() {
    return http.get<ContactDataType>(`/contacts/default`);
  }

  create(data: ContactType) {
    return http.post<ContactDataType>("/contacts", data);
  }

  updateDefault(data: ContactType) {
    return http.put<ContactDataType>(`/contacts/default`, data);
  }

  deleteDefault() {
    return http.delete<DeleteDataType>(`/contacts/default`);
  }
}

export default new ContactService();
