// Authorization
export const AUTHORIZE = "authorize";
export const GOOGLE_REFRESH_TOKEN = "google_refresh_token";

// Config
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
export const CLIENT_BASE_URL = process.env.REACT_APP_CLIENT_BASE_URL;

// AWS
export const CLOUDFRONT = process.env.REACT_APP_CLOUDFRONT_BASE_URL;

// Routing | Exclude this routes from being tracked
export const UNTRACKED_ROUTES = ["/login", "/signup"];

export const menuItems = [
  { name: "projects", path: "/" },
  { name: "contact", path: "/contact" },
  { name: "biography", path: "/biography" },
  { name: "Desktop Randoms", path: "/desktop-random" },
  { name: "mobile Random", path: "/mobile-random" },
];

export const imageBlocks = [
  { value: 1, count: 1, ratio: "1290x800" },
  { value: 2, count: 1, ratio: "1728x1117" },
  { value: 3, count: 1, ratio: "519x800" },
  { value: 4, count: 2, ratio: "630x800" },
  { value: 5, count: 1, ratio: "519x800" },
  { value: 6, count: 1, ratio: "631x800" },
  { value: 7, count: 2, ratio: "300x400 | 630x800" },
  { value: 8, count: 2, ratio: "630x800 | 300x400" },
  { value: 9, count: 1, ratio: "864x1067" },
  { value: 10, count: 1, ratio: "864x1067" },
  { value: 11, count: 3, ratio: "300x400" },
  { value: 12, count: 3, ratio: "300x400" },
  { value: 13, count: 0, ratio: "300x385" },
  { value: 14, count: 3, ratio: "410x600" },
  { value: 15, count: 3, ratio: "410x300" },
  { value: 16, count: 2, ratio: "880x580 | 375x275" },
  { value: 17, count: 2, ratio: "375x275 | 880x580" },
  { value: 18, count: 4, ratio: "630x385" },
  { value: 19, count: 2, ratio: "630x461" },
];

export const randomBlocks = [
  { value: 1, count: 1 },
  { value: 2, count: 1 },
  { value: 3, count: 2 },
  { value: 4, count: 1 },
  { value: 5, count: 1 },
  { value: 6, count: 1 },
  { value: 7, count: 1 },
  { value: 8, count: 2 },
  { value: 9, count: 3 },
];

export const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const years = () => {
  const now = new Date();
  const startYear = now.getFullYear() + 20;
  const years = Array.from(new Array(50), (val, index) => startYear - index);
  return years;
};

export const aspectRatios = ["16:9", "1:1", "4:5", "2:3", "9:16"];
