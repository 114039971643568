import React from "react";
import { CardMedia, Stack } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", width: "100%" }}
    >
      <a
        href="/login"
        onClick={(e) => {
          e.preventDefault();
          loginWithRedirect();
        }}
      >
        <CardMedia
          component="img"
          src="logo.svg"
          sx={{ width: "100%", height: "223px", cursor: "pointer" }}
        />
      </a>
    </Stack>
  );
};

export default LoginPage;
