import { Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { SketchPicker } from "react-color";

const UploadColorPicker = ({
  previews,
  setPreviews,
  image,
  open,
  handleClose,
  index,
}) => {
  const [color, setColor] = useState<any>({ hex: image.color });

  const handleColorChange = () => {
    const { hex } = color;
    previews[index].color = hex;
  };

  const handleChange = (col) => {
    setColor(col);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={() => {
        handleColorChange();
        handleClose();
      }}
      scroll="body"
    >
      <DialogContent sx={{ p: 0 }}>
        <SketchPicker color={color} onChangeComplete={handleChange} />
      </DialogContent>
    </Dialog>
  );
};

export default UploadColorPicker;
