import { ImageBlocksDataType } from "../../interfaces/services";
import http from "../http";

class ImageBlockService {
  // Blocks
  getProjectBlocks(id: string) {
    return http.get<ImageBlocksDataType>(`/project-blocks/${id}`);
  }

  createBlock(data: any) {
    return http.post<any>(`/blocks`, data);
  }

  updateBlock(data: any, id: string) {
    return http.put<any>(`/blocks/${id}`, data);
  }

  updateBlockRank(data: string[]) {
    return http.put<any>(`/blocks-rank`, data);
  }

  deleteBlock(id: string) {
    return http.delete<any>(`/blocks/${id}`);
  }

  // Images
  getImageAll() {
    return http.get<any>(`/images`);
  }

  createImages(data: any, id: string) {
    return http.post<any>(`/images-upload/${id}`, data);
  }

  updateImage(data: any, id: string) {
    return http.put<any>(`/images/${id}`, data);
  }

  updateImageRank(data: string[]) {
    return http.put<any>(`/images-rank`, data);
  }

  deleteImage(id: string) {
    return http.delete<any>(`/images/${id}`);
  }
}

export default new ImageBlockService();
