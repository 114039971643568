import { Typography, Stack, Box, Button, FormHelperText } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  convertContentToHTML,
  getParsedExhibitions,
} from "../../utilities/helpers";
import biographyService from "../../api/services/biography.service";
import { RefreshOutlined } from "@mui/icons-material";
import FormExhibitions from "./FormExhibitions";
import FormClients from "./FormClients";
import FormPublications from "./FormPublications";
import FormAbout from "./FormAbout";
import FormSeo from "./FormSeo";

const Biography: React.FC = () => {
  const theme: Theme = useTheme();
  const [error, setError] = useState<any>("");
  const [biography, setBiography] = useState<any>(null);
  const [about, setAbout] = useState<any>(null);
  const [exhibitions, setExhibitions] = useState<any>([]);
  const [publications, setPublications] = useState<any>([]);
  const [clients, setClients] = useState<any>([]);

  const [openAbout, setOpenAbout] = useState<any>(false);
  const [openExhibit, setOpenExhibit] = useState<any>(false);
  const [openClients, setOpenClients] = useState<any>(false);
  const [openPubs, setOpenPubs] = useState<any>(false);
  const [openSeo, setOpenSeo] = useState<any>(false);

  function initializeBiography() {
    setError("");
    biographyService
      .getDefault()
      .then((res) => {
        if (res.data.success) {
          const bio = res.data.resource;
          if (!bio) return;
          setBiography(bio);
          setExhibitions(getParsedExhibitions(bio.exhibitions));
          setPublications(bio.publications);
          setClients(bio.clients);
          setAbout(convertContentToHTML(bio.about));
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setError(error.message);
      });
  }

  useEffect(() => {
    initializeBiography();
  }, []);

  const handleOpenFormAbout = () => {
    setOpenAbout((old) => !old);
  };

  const handleOpenFormExhibitions = () => {
    setOpenExhibit((old) => !old);
  };
  const handleOpenFormPublications = () => {
    setOpenPubs((old) => !old);
  };
  const handleOpenFormClients = () => {
    setOpenClients((old) => !old);
  };

  const handleOpenFormSeo = () => {
    setOpenSeo((old) => !old);
  };

  const refreshBiography = () => {
    setError("");
    initializeBiography();
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          width: "100%",
          mt: "65px",
          height: "calc(100vh - 65px)",
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ width: "100%", mb: "30px" }}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Button
              onClick={refreshBiography}
              sx={{ width: { xs: "100%", sm: "250px" } }}
            >
              <RefreshOutlined />
              &nbsp;&nbsp;Refresh
            </Button>

            <Button
              onClick={handleOpenFormSeo}
              sx={{ width: { xs: "100%", sm: "250px" } }}
            >
              Edit Seo
            </Button>
          </Stack>

          {error && (
            <FormHelperText sx={{ color: theme.colors.error, pt: "10px" }}>
              {error}
            </FormHelperText>
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={7}
          sx={{
            width: { xs: "calc(100vw - 30px)", sm: "calc(100vw - 50px)" },
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "35%",
              height: "100%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="hide-scrollbar"
              sx={{
                width: "100%",
                height: "100%",
                maxHeight: "100%",
                overflowY: "scroll",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                sx={{
                  width: "100%",
                  pb: "20px",
                }}
              >
                <Button
                  onClick={handleOpenFormAbout}
                  sx={{ width: { xs: "100%", sm: "200px" } }}
                >
                  Edit About
                </Button>
              </Stack>

              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  width: "100%",
                }}
              >
                {about && (
                  <Typography
                    className="dangerously"
                    sx={{
                      textAlign: "justify",
                      mb: "20px",
                      lineHeight: theme.size[19],
                    }}
                    dangerouslySetInnerHTML={about}
                  ></Typography>
                )}
              </Stack>
            </Stack>
          </Box>

          <Box
            sx={{
              height: "100%",
              maxHeight: "100%",
              width: "35%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="hide-scrollbar"
              sx={{
                width: "100%",
                height: "100%",
                maxHeight: "100%",
                overflowY: "scroll",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                sx={{
                  width: "100%",
                  pb: "20px",
                }}
              >
                <Button
                  onClick={handleOpenFormExhibitions}
                  sx={{ width: { xs: "100%", sm: "200px" } }}
                >
                  Edit Exhibitions
                </Button>
              </Stack>

              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  width: "100%",
                  mb: "20px",
                }}
              >
                {exhibitions.upcoming && exhibitions.upcoming.length > 0 && (
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{ width: "100%", mb: "80px" }}
                  >
                    <Typography
                      sx={{
                        pb: "10px",
                        textTransform: "uppercase",
                        fontSize: theme.size[12],
                      }}
                    >
                      Upcoming
                    </Typography>
                    {exhibitions.upcoming.map((exhibition, index) => (
                      <Stack
                        key={`${index}-${exhibition.content}`}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{ width: "100%" }}
                      >
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          sx={{ width: "80px" }}
                        >
                          <Typography
                            sx={{
                              lineHeight: theme.size[19],
                              width: "100%",
                            }}
                          >
                            {`${exhibition.day}.${exhibition.month}.${exhibition.year}`}
                          </Typography>

                          {exhibition.customDate && (
                            <Typography
                              sx={{
                                width: "100%",
                                fontStyle: "italic",
                                fontSize: "12px",
                              }}
                            >
                              {exhibition.customDate}
                            </Typography>
                          )}
                        </Stack>

                        <Typography
                          sx={{
                            textAlign: "left",
                            lineHeight: theme.size[19],
                            width: "calc(100% - 80px)",
                          }}
                        >
                          {exhibition.content}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}

                {exhibitions.past && exhibitions.past.length > 0 && (
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      sx={{
                        pb: "10px",
                        textTransform: "uppercase",
                        fontSize: theme.size[12],
                      }}
                    >
                      past
                    </Typography>
                    {exhibitions.past.map((exhibition) => (
                      <Typography
                        key={`exhibition-${exhibition.content}`}
                        sx={{
                          textAlign: "justify",
                          lineHeight: theme.size[19],
                          textIndent: "-45px",
                          pl: "45px",
                        }}
                      >
                        <span style={{ marginRight: "15px" }}>
                          {exhibition.year}
                        </span>
                        <span>{exhibition.content}</span>
                      </Typography>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Box>

          <Box
            sx={{
              height: "100%",
              maxHeight: "100%",
              width: "15%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="hide-scrollbar"
              sx={{
                width: "100%",
                height: "100%",
                maxHeight: "100%",
                overflowY: "scroll",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                sx={{
                  width: "100%",
                  pb: "20px",
                }}
              >
                <Button
                  onClick={handleOpenFormPublications}
                  sx={{ width: { xs: "100%", sm: "200px" } }}
                >
                  Edit Publications
                </Button>
              </Stack>

              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  width: "100%",
                  mb: "20px",
                }}
              >
                {publications.map((publication) => (
                  <Box
                    sx={{
                      p: "5px",
                      borderBottom: "1px solid grey",
                      width: "100%",
                    }}
                  >
                    <Typography
                      key={publication.name}
                      sx={{
                        textAlign: "justify",
                        fontWeight: 500,
                        lineHeight: theme.size[19],
                        overflowWrap: "anywhere",
                      }}
                    >
                      {publication.name}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        lineHeight: theme.size[19],
                        overflowWrap: "anywhere",
                      }}
                    >
                      {publication.url}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Box>

          <Box
            sx={{
              height: "100%",
              maxHeight: "100%",
              width: "15%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              className="hide-scrollbar"
              sx={{
                width: "100%",
                height: "100%",
                maxHeight: "100%",
                overflowY: "scroll",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                sx={{
                  width: "100%",
                  pb: "20px",
                }}
              >
                <Button
                  onClick={handleOpenFormClients}
                  sx={{ width: { xs: "100%", sm: "200px" } }}
                >
                  Edit Clients
                </Button>
              </Stack>

              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  width: "100%",
                  mb: "20px",
                }}
              >
                {clients.map((clients) => (
                  <Box
                    key={clients.name}
                    sx={{
                      p: "5px",
                      borderBottom: "1px solid grey",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontWeight: 500,
                        lineHeight: theme.size[19],
                        overflowWrap: "anywhere",
                      }}
                    >
                      {clients.name}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        lineHeight: theme.size[19],
                        overflowWrap: "anywhere",
                      }}
                    >
                      {clients.url}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <FormExhibitions
        biography={biography}
        setBiography={setBiography}
        open={openExhibit}
        refreshBiography={refreshBiography}
        handleClose={handleOpenFormExhibitions}
      />

      <FormClients
        biography={biography}
        setBiography={setBiography}
        open={openClients}
        refreshBiography={refreshBiography}
        handleClose={handleOpenFormClients}
      />

      <FormPublications
        biography={biography}
        setBiography={setBiography}
        open={openPubs}
        refreshBiography={refreshBiography}
        handleClose={handleOpenFormPublications}
      />

      <FormAbout
        biography={biography}
        setBiography={setBiography}
        open={openAbout}
        refreshBiography={refreshBiography}
        handleClose={handleOpenFormAbout}
      />

      <FormSeo
        biography={biography}
        setBiography={setBiography}
        open={openSeo}
        refreshBiography={refreshBiography}
        handleClose={handleOpenFormSeo}
      />
    </>
  );
};

export default Biography;
