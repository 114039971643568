import { Typography, Stack, Box, Button, FormHelperText } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { convertContentToHTML } from "../../utilities/helpers";
import FormContact from "./FormContact";
import contactService from "../../api/services/contact.service";
import { RefreshOutlined } from "@mui/icons-material";

const Contact: React.FC = () => {
  const theme: Theme = useTheme();
  const [imprint, setImprint] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [contact, setContact] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  function initializeContact() {
    contactService
      .getDefault()
      .then((res) => {
        if (res.data.success) {
          const cont = res.data.resource;
          if (!cont) return;
          setContact(cont);
          setImprint(convertContentToHTML(cont.imprint));
          setData(convertContentToHTML(cont.data));
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  }

  useEffect(() => {
    initializeContact();
  }, []);

  const handleOpenFormContact = () => {
    setOpen((old) => !old);
  };

  const refreshContact = () => {
    setError("");
    initializeContact();
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          width: "100%",
          mt: "65px",
          height: "calc(100vh - 65px)",
        }}
      >
        {error && (
          <FormHelperText sx={{ color: theme.colors.error, pb: "10px" }}>
            {error}
          </FormHelperText>
        )}

        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          sx={{
            width: "100%",
            pb: "20px",
          }}
        >
          <Button
            onClick={handleOpenFormContact}
            sx={{ width: { xs: "100%", sm: "200px" } }}
          >
            Edit Contact
          </Button>

          <Button onClick={refreshContact} sx={{ minWidth: "32px" }}>
            <RefreshOutlined />
          </Button>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={5}
          sx={{
            width: "100%",
            minHeight: "100%",
            p: "25px 0",
          }}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              width: { xs: "100%", sm: "33%" },
              height: "100%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ width: "100%", pb: "20px" }}
            >
              <Typography sx={{ textTransform: "uppercase" }}>
                {contact?.email}
              </Typography>
              <Typography>{contact?.phone}</Typography>
            </Stack>

            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{
                width: "100%",
                height: "fit-content",
              }}
            >
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 500,
                  fontSize: theme.size[14],
                  p: "1px 0",
                  mb: "10px",
                }}
              >
                Menus
              </Typography>

              {contact?.menu.map((menu) => (
                <Box
                  key={menu.name}
                  sx={{
                    p: "5px",
                    borderBottom: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "justify",
                      fontWeight: 500,
                      lineHeight: theme.size[19],
                      overflowWrap: "anywhere",
                    }}
                  >
                    {menu.name}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "justify",
                      lineHeight: theme.size[19],
                      overflowWrap: "anywhere",
                    }}
                  >
                    {menu.url}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="hide-scrollbar"
            sx={{
              width: { xs: "100%", sm: "33%" },
              height: "100%",
              overflowY: { xs: "inherit", sm: "scroll" },
            }}
          >
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: 500,
                fontSize: theme.size[14],
                p: "1px 0",
                mb: "20px",
              }}
            >
              Imprint
            </Typography>

            <Typography
              className="dangerously"
              sx={{
                textAlign: "justify",
                mb: "20px",
                lineHeight: theme.size[19],
              }}
              dangerouslySetInnerHTML={imprint}
            />
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className="hide-scrollbar"
            sx={{
              width: { xs: "100%", sm: "33%" },
              height: "100%",
              overflowY: { xs: "inherit", sm: "scroll" },
            }}
          >
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: 500,
                fontSize: theme.size[14],
                p: "1px 0",
                mb: "20px",
              }}
            >
              Data
            </Typography>
            <Typography
              className="dangerously"
              sx={{
                textAlign: "justify",
                mb: "20px",
                lineHeight: theme.size[19],
              }}
              dangerouslySetInnerHTML={data}
            />
          </Stack>

          <FormContact
            contact={contact}
            setContact={setContact}
            open={open}
            refreshContact={refreshContact}
            handleClose={handleOpenFormContact}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Contact;
