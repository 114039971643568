import { Cancel, Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  FormHelperText,
  Box,
  Stack,
  TextField,
  IconButton,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Theme, useTheme } from "@mui/material/styles";
import biographyService from "../../api/services/biography.service";
import { convertEditorToRaw } from "../../utilities/helpers";
import { days, months, years } from "../../utilities/constants";

const FormExhibitions = ({
  biography,
  setBiography,
  open,
  handleClose,
  refreshBiography,
}) => {
  const theme: Theme = useTheme();
  const date = new Date();

  const [error, setError] = useState<string>("");
  const [timeError, setTimeError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [exhibitions, setExhibitions] = useState<any>([]);
  const [content, setContent] = useState<string>("");
  const [day, setDay] = useState<number>(date.getDate());
  const [month, setMonth] = useState<number>(date.getMonth() + 1);
  const [year, setYear] = useState<number>(date.getFullYear());
  const [customDate, setCustomDate] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [oldExhibit, setOldExhibit] = useState<any>(null);
  const [update, setUpdate] = useState<boolean>(false);

  useEffect(() => {
    setSuccess("");
    setError("");
    if (!biography) return;
    setExhibitions(biography.exhibitions);
  }, [biography]);

  function createExhibitions(values: any) {
    biographyService
      .create(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Exhibitions created");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  function updateExhibitions(values: any) {
    biographyService
      .updateDefault(values)
      .then((res) => {
        if (res.data.success) {
          setSuccess("Exhibitions updated");
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
    setSubmitting(false);
  }

  const handleSubmitExhibitions = () => {
    setSuccess("");
    setError("");
    setTimeError("");
    setSubmitting(true);

    if (biography) {
      updateExhibitions({ exhibitions });
    } else {
      const about = convertEditorToRaw(EditorState.createEmpty());
      const params = { exhibitions, about, clients: [], publications: [] };
      createExhibitions(params);
    }
  };

  const handleDeleteExhibition = (exhibit: any) => {
    const filt = exhibitions.filter((i) => i.timestamp !== exhibit.timestamp);
    setExhibitions(filt);
  };

  const handleEditExhibition = () => {
    setSuccess("");
    setError("");
    setTimeError("");

    if (!oldExhibit) return;

    const oldContent = oldExhibit.content;
    const oldTime = oldExhibit.time;

    const _content = content.trim();
    if (!_content || !day || !month || !year) {
      setTimeError("Content, day, month or year is not specified");
      return;
    }

    const date = new Date(`${year}-${month}-${day}`);
    const time = date.getTime();

    // convert time to boolean !!time. check if time is false !!!time
    if (!!!time) {
      setTimeError("Invalid Date");
      return;
    }

    const included = exhibitions.find((item) => {
      if (item.time === time && item.content === _content) return true;
      return false;
    });

    if (included) {
      setTimeError("Identical exhibition already exists");
      return;
    }

    const items = exhibitions.filter((item) => {
      if (item.time === oldTime && item.content === oldContent) return false;
      return true;
    });

    const timestamp = `${Date.now() + Math.random()}`;
    const exhibit = {
      time,
      day,
      month,
      year,
      content: _content,
      timestamp,
      customDate: customDate.trim(),
    };

    items.push(exhibit);
    setExhibitions(items);

    setContent("");
    setCustomDate("");
    setTimeError("");
    setUpdate(false);
    setOldExhibit(null);
  };

  const handleAddExhibition = () => {
    setSuccess("");
    setError("");
    setTimeError("");
    const _content = content.trim();
    if (!_content || !day || !month || !year) {
      setTimeError("Content, day, month or year is not specified");
      return;
    }
    const date = new Date(`${year}-${month}-${day}`);
    const time = date.getTime();

    // convert time to boolean !!time. check if time is false !!!time
    if (!!!time) {
      setTimeError("Invalid Date");
      return;
    }

    const included = exhibitions.find((item) => {
      if (item.time === time && item.content === _content) return true;
      return false;
    });

    if (included) {
      setTimeError("Identical exhibition already exists");
      return;
    }

    const timestamp = `${Date.now() + Math.random()}`;
    const exhibit = {
      time,
      day,
      month,
      year,
      content: _content,
      timestamp,
      customDate: customDate.trim(),
    };
    setExhibitions((old) => [...old, exhibit].sort((a, b) => b.time - a.time));
    setContent("");
    setTimeError("");
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} scroll="body">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", p: { xs: "10px 15px 0", sm: "20px 24px 0" } }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            disableElevation
            disabled={submitting}
            fullWidth
            onClick={handleSubmitExhibitions}
            sx={{ width: "30%" }}
          >
            Update Exhibitions
          </Button>

          <Typography
            sx={{
              fontWeight: "700",
              fontSize: theme.size[15],
              color: theme.colors.grey700,
            }}
          >
            Exhibitions Form
          </Typography>
        </Stack>

        <IconButton
          style={{ color: "gray" }}
          onClick={() => {
            refreshBiography();
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Stack>

      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: "100%" }}
        >
          {timeError && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
              {timeError}
            </FormHelperText>
          )}

          {error && (
            <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
              {error}
            </FormHelperText>
          )}

          {success && (
            <FormHelperText sx={{ color: theme.colors.success, pb: "15px" }}>
              {success}
            </FormHelperText>
          )}

          <TextField
            fullWidth
            label="Exhibition Content"
            value={content}
            name="content"
            size="small"
            onBlur={(e) => setContent(e.target.value)}
            onChange={(e) => setContent(e.target.value)}
            sx={{ width: "100%" }}
          />

          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              select
              label="Day"
              name="day"
              size="small"
              value={day}
              onBlur={(e) => setDay(Number(e.target.value))}
              onChange={(e) => setDay(Number(e.target.value))}
              sx={{
                width: { xs: "100%", sm: "15%" },
                "& .MuiOutlinedInput-input": { p: "7px 14px" },
              }}
            >
              {days.map((d) => (
                <MenuItem key={d} value={d}>
                  {d}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              select
              label="Month"
              name="month"
              size="small"
              value={month}
              onBlur={(e) => setMonth(Number(e.target.value))}
              onChange={(e) => setMonth(Number(e.target.value))}
              sx={{
                width: { xs: "100%", sm: "15%" },
                "& .MuiOutlinedInput-input": { p: "7px 14px" },
              }}
            >
              {months.map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              select
              label="Year"
              name="year"
              size="small"
              value={year}
              onBlur={(e) => setYear(Number(e.target.value))}
              onChange={(e) => setYear(Number(e.target.value))}
              sx={{
                width: { xs: "100%", sm: "20%" },
                "& .MuiOutlinedInput-input": { p: "7px 14px" },
              }}
            >
              {years().map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              label="Custom Date"
              value={customDate}
              name="customDate"
              size="small"
              onBlur={(e) => setCustomDate(e.target.value)}
              onChange={(e) => setCustomDate(e.target.value)}
              sx={{ width: { xs: "100%", sm: "50%" } }}
            />
          </Stack>

          {update && (
            <Button onClick={handleEditExhibition} sx={{ width: "100%" }}>
              Update Exhibition
            </Button>
          )}
          {!update && (
            <Button onClick={handleAddExhibition} sx={{ width: "100%" }}>
              Add Exhibition
            </Button>
          )}

          {exhibitions.map((exhibit) => (
            <Stack
              key={exhibit.content}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
              sx={{
                width: "100%",
                padding: "6px 10px",
                backgroundColor: "#f5f5f5",
                borderRadius: "5px",
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                onClick={() => {
                  setContent(exhibit.content);
                  setDay(exhibit.day);
                  setMonth(exhibit.month);
                  setYear(exhibit.year);
                  setCustomDate(exhibit.customDate);
                  setUpdate(true);
                  setOldExhibit(exhibit);
                }}
                sx={{ width: "90%", cursor: "pointer" }}
              >
                <Stack
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{ width: "15%" }}
                >
                  <Typography sx={{ width: "100%" }}>
                    <strong>{`${exhibit.day}.${exhibit.month}.${exhibit.year}`}</strong>
                  </Typography>

                  {exhibit.customDate && (
                    <Typography
                      sx={{
                        width: "100%",
                        fontStyle: "italic",
                        fontSize: "12px",
                      }}
                    >
                      {exhibit.customDate}
                    </Typography>
                  )}
                </Stack>

                <Typography sx={{ width: "75%" }}>{exhibit.content}</Typography>
              </Stack>
              <Box sx={{ width: "10%" }}>
                <IconButton onClick={() => handleDeleteExhibition(exhibit)}>
                  <Cancel />
                </IconButton>
              </Box>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FormExhibitions;
