import { Stack, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { useEffect } from "react";

const CategoryMenu = ({ categories, category, handleFilter, projects }) => {
  const theme: Theme = useTheme();

  useEffect(() => {}, []);

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      sx={{
        p: "6px 0",
        mb: "10px",
        backgroundColor: theme.colors.paper,
        width: "100%",
        top: { sm: "200px" },
      }}
    >
      <Typography
        onClick={() => handleFilter(null, projects)}
        sx={{
          textTransform: "uppercase",
          fontSize: theme.size[12],
          letterSpacing: "0.6px",
          fontWeight: 500,
          cursor: "pointer",
          color: category === null ? theme.colors.black : theme.colors.grey400,
        }}
      >
        All
      </Typography>
      {categories.map((cat) => (
        <Typography
          key={cat.name}
          onClick={() => handleFilter(cat, projects)}
          sx={{
            cursor: "pointer",
            textTransform: "uppercase",
            fontSize: theme.size[12],
            letterSpacing: "0.6px",
            fontWeight: 500,
            color:
              category?.slug === cat.slug
                ? theme.colors.black
                : theme.colors.grey400,
          }}
        >
          {cat.name}
        </Typography>
      ))}
    </Stack>
  );
};

export default CategoryMenu;
