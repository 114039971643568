import {
  Box,
  Stack,
  Button,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  FormHelperText,
  CardMedia,
  Card,
} from "@mui/material";
import { useState, useEffect } from "react";
import UploadImages from "../../components/UploadImages";
import { Theme, useTheme } from "@mui/material/styles";
import LoaderBigger from "../../components/LoaderBigger";
import { Close, DeleteOutline } from "@mui/icons-material";
import projectService from "../../api/services/project.service";
import imageBlockService from "../../api/services/imageBlock.service";
import { imgUrl } from "../../utilities/helpers";

const FormPreviewImage = ({
  project,
  setProject,
  open,
  handleClose,
  refreshProjects,
}) => {
  const theme: Theme = useTheme();

  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [totalSize, setTotalSize] = useState<number>(0);
  const [preview, setPreview] = useState<any>(null);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleBlockChange = (id: any) => {
    setImages((old) => [...old]);
    setPreviews((old) => [...old]);
  };

  useEffect(() => {
    setPreview(project?.preview);
  }, [project]);

  const handleAddPreview = () => {
    setError("");
    setSuccess("");
    setSubmitting(true);
    if (images.length === 0) {
      setSubmitting(false);
      return;
    }
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("files", image);
    });

    projectService
      .uploadProjectPreviewImage(formData, project._id)
      .then((res) => {
        if (res.data.success) {
          setImages([]);
          setPreviews([]);
          setSubmitting(false);
          setTotalSize(0);
          setPreview(res.data.resource);
          setSuccess("Project preview created");
          refreshProjects();
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error.message);
      });
  };

  const handleDeletePreview = (image: any) => {
    setError("");
    setSuccess("");
    var answer = window.confirm("Are you sure?");
    if (!answer) return;

    imageBlockService
      .deleteImage(image._id)
      .then((res) => {
        if (res.data.success) {
          refreshProjects();
          setSuccess("Project preview deleted");
          setPreview(null);
        } else {
          setError(res.data.error?.message);
        }
      })
      .catch((error) => setError(error.message));
  };

  return (
    <>
      {submitting && <LoaderBigger />}
      <Dialog fullWidth maxWidth="sm" open={open} scroll="body">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", p: { xs: "10px 15px 0", sm: "20px 24px 0" } }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: theme.size[17],
              color: theme.colors.grey700,
            }}
          >
            Add Preview Image
          </Typography>

          <IconButton
            style={{ color: "gray" }}
            onClick={() => {
              setError("");
              setSuccess("");
              setTotalSize(0);
              handleClose();
              setProject(null);
              setPreview(null);
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: "100%" }}
          >
            {error && (
              <FormHelperText sx={{ color: theme.colors.error, pb: "15px" }}>
                {error}
              </FormHelperText>
            )}

            {success && (
              <FormHelperText sx={{ color: theme.colors.success, pb: "15px" }}>
                {success}
              </FormHelperText>
            )}

            {!preview && (
              <Button
                onClick={handleAddPreview}
                disabled={totalSize > 20_000_000}
                sx={{
                  p: "8px",
                  width: "20%",
                  backgroundColor: `${
                    totalSize > 20_000_000 ? "red" : "#43a310"
                  }`,
                }}
              >
                Submit
              </Button>
            )}

            <Box sx={{ width: "100%" }}>
              <UploadImages
                totalSize={totalSize}
                setTotalSize={setTotalSize}
                inputBoxSx={{ width: { xs: "100%", sm: "60%" } }}
                imageHeight="120px"
                multiple
                max={1}
                identifier={null}
                required
                handleBlockChange={handleBlockChange}
                files={images}
                setFiles={setImages}
                previews={previews}
                setPreviews={setPreviews}
              />
            </Box>

            {preview && (
              <Card
                elevation={0}
                sx={{
                  padding: "0",
                  margin: "4px",
                  borderRadius: "2px",
                  position: "relative",
                  width: "fit-content",
                  maxHeight: "120px",
                  mt: "15px",
                }}
              >
                {preview.slug && (
                  <CardMedia
                    sx={{
                      height: "120px",
                      width: "fit-content",
                      display: "inline-block",
                    }}
                    component="img"
                    src={imgUrl(preview.slug)}
                    alt={preview.alt}
                  />
                )}

                <IconButton
                  onClick={() => handleDeletePreview(preview)}
                  sx={{
                    color: theme.colors.error,
                    position: "absolute",
                    top: "0",
                    right: "0",
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Card>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormPreviewImage;
