import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import LoginPage from "./views/Auth/Login";
import ProtectedRoute from "./views/Auth/ProtectedRoute";
import Biography from "./views/Biography";
import Contact from "./views/Contact";
import DesktopRandom from "./views/DesktopRandom";
import MobileRandom from "./views/MobileRandom";
import NotFound from "./views/NotFound";
import Projects from "./views/Projects";

const App = () => {
  useEffect(() => {}, []);

  return (
    <MainLayout>
      <Routes>
        <Route path="">
          <Route path="" element={<ProtectedRoute component={Projects} />} />
          <Route
            path="contact"
            element={<ProtectedRoute component={Contact} />}
          />
          <Route
            path="biography"
            element={<ProtectedRoute component={Biography} />}
          />
          <Route
            path="desktop-random"
            element={<ProtectedRoute component={DesktopRandom} />}
          />
          <Route
            path="mobile-random"
            element={<ProtectedRoute component={MobileRandom} />}
          />
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default App;
