import { Theme, useTheme } from "@mui/material/styles";
import { AppBar, Box, Toolbar } from "@mui/material";
import { UNTRACKED_ROUTES } from "../../utilities/constants";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { setPreviousPath } from "../../store/actions/previousPathActions";
import React, { useEffect } from "react";
import Header from "./Header";
import { MainLayoutType } from "../../interfaces/components";

const MainLayout: React.FC<MainLayoutType> = ({ children }) => {
  const theme: Theme = useTheme();
  const dispatch = useAppDispatch();
  let location = useLocation();

  useEffect(() => {
    function handleDispatch() {
      // Update location everytime. Exclude auth routes
      if (!UNTRACKED_ROUTES.includes(location.pathname)) {
        dispatch(setPreviousPath(location.pathname));
      }
    }

    handleDispatch();
  }, [dispatch, location.pathname]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
        >
          <Toolbar sx={{ padding: { xs: "0 15px", sm: "0 25px" } }}>
            <Header />
          </Toolbar>
        </AppBar>

        {/* main content */}
        <Box
          sx={{
            ...theme.typography.mainContent,
            padding: { xs: "0 15px", sm: "0 25px" },
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;
